define("bito-shop-frontend/components/form-single-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label for={{attributeId}}>
    {{attributeName}}
    {{#unless required}}
      <span class="optional text-small">optional</span>
    {{/unless}}
  </label>
  {{input type=type
          required=required
          value=value
          id=attributeId
          classBinding="errors.length:error"
          placeholder=placeholder}}
  <FormErrors @errors={{errors}} type="error" />
  
  
  */
  {
    "id": "t01dktco",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[15,\"for\",[34,0]],[12],[2,\"\\n  \"],[1,[34,1]],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"optional text-small\"],[12],[2,\"optional\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[1,[30,[36,10],null,[[\"type\",\"required\",\"value\",\"id\",\"placeholder\",\"class\"],[[35,9],[35,2],[35,8],[35,0],[35,7],[30,[36,6],[[30,[36,5],[[35,4,[\"length\"]],\"error\"],null],\" \"],null]]]]],[2,\"\\n\"],[8,\"form-errors\",[[24,4,\"error\"]],[[\"@errors\"],[[34,4]]],null],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"attributeId\",\"attributeName\",\"required\",\"unless\",\"errors\",\"if\",\"concat\",\"placeholder\",\"value\",\"type\",\"input\"]}",
    "moduleName": "bito-shop-frontend/components/form-single-input.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.errors = this.errors || [];
    },
    tagName: 'div',
    classNames: ['grid__col-24'],
    required: false,
    type: 'text',
    placeholder: null,
    attributeName: null
  }));

  _exports.default = _default;
});