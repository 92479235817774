define("bito-shop-frontend/services/utils", ["exports", "jquery", "bito-shop-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notYetImplemented: function notYetImplemented() {
      if (_environment.default.environment === 'development') {
        alert("Sorry, nothing's here yet");
      }
    },
    scrollTo: function scrollTo() {
      var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.scrolldown__target';
      var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2000;
      var targetElement = (0, _jquery.default)(selector);

      if (targetElement.length) {
        (0, _jquery.default)('html, body').animate({
          scrollTop: targetElement.offset().top + offset
        }, duration);
      }
    },
    scrollToTop: function scrollToTop(delay) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if ((0, _jquery.default)(window).scrollTop()) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: 0
          }, {
            duration: delay || 300,
            done: function done() {
              return resolve();
            },
            fail: function fail() {
              return reject();
            }
          });
        } else {
          resolve();
        }
      });
    }
  });

  _exports.default = _default;
});