define("bito-shop-frontend/components/content-modal", ["exports", "jquery", "ember-cli-skip-links/mixins/skip-link"], function (_exports, _jquery, _skipLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.model}}
    {{component
      this.childComponentName
      model=this.model
      option=this.options
      isActive=this.isActive
      closeModal=this.closeModal
    }}
  {{/if}}
  
  */
  {
    "id": "2kYrr0Am",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"model\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,0,[\"childComponentName\"]]],[[\"model\",\"option\",\"isActive\",\"closeModal\"],[[32,0,[\"model\"]],[32,0,[\"options\"]],[32,0,[\"isActive\"]],[32,0,[\"closeModal\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"if\"]}",
    "moduleName": "bito-shop-frontend/components/content-modal.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_skipLink.default, {
    init: function init() {
      this._super.apply(this, arguments); // register with contentModalService


      this.contentModal.registerComponent(this);
    },
    mystique: Ember.inject.service(),
    contentModal: Ember.inject.service(),
    tagName: 'div',
    classNames: ['layer'],
    classNameBindings: ['isActive:is-visible', 'isActive::is-hidden'],
    toggleOverlay: false,
    model: Ember.computed.alias('contentModal.model'),
    options: Ember.computed.reads('contentModal.options'),
    onActiveChange: Ember.observer('isActive', function () {
      if (this.get('isActive')) {
        (0, _jquery.default)('html').addClass('noscroll');
      } else {
        (0, _jquery.default)('html').removeClass('noscroll');
      }
    }),
    childComponentName: Ember.computed('contentModal.modalType', function () {
      return "modal-".concat(this.contentModal.modalType);
    }),
    actions: {
      closeModal: function closeModal() {
        this.contentModal.closeModal();
      }
    }
  }));

  _exports.default = _default;
});