define("bito-shop-frontend/components/relation-select", ["exports", "bito-shop-frontend/templates/components/relation-select"], function (_exports, _relationSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _relationSelect.default,
    model: null,
    // relation instance
    relation: null,
    // instance relation gets assigned to
    relationType: 'one',
    // ['one', 'many']
    idAttr: '',
    type: 'checkbox',
    isSelected: Ember.computed('relation.id', 'relation.[]', 'relationType', function () {
      switch (this.get('relationType')) {
        case 'one':
          return this.get('relation.id') === this.get('model.id');

        case 'many':
          return this.get('relation').mapBy('id').includes(this.get('model.id'));

        default:
          return false;
      }
    }),
    beforeUpdate: Function.prototype,
    afterUpdate: Function.prototype,
    actions: {
      onChange: function onChange(event) {
        this.get('beforeUpdate').call(this, event);
        this.send('setRelation', event);
        this.get('afterUpdate').call(this, event);
      },
      setRelation: function setRelation(event) {
        switch (this.get('relationType')) {
          case 'one':
            this.send('_setOneRelation', event);
            break;

          case 'many':
            this.send('_setManyRelation', event);
            break;
        }
      },
      _setOneRelation: function _setOneRelation(event) {
        if (event.target.checked) {
          this.set('relation', this.get('model'));
        } else {
          this.set('relation', null);
        }
      },
      _setManyRelation: function _setManyRelation(event) {
        if (event.target.checked) {
          this.get('relation').pushObject(this.get('model'));
        } else {
          this.get('relation').removeObject(this.get('model'));
        }
      }
    }
  });

  _exports.default = _default;
});