define("bito-shop-frontend/initializers/mystique", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('service:mystique', 'router', 'router:main');
    application.inject('service:mystique', 'utils', 'service:utils');
    application.inject('service:mystique', 'store', 'service:store');
    application.inject('service:mystique', 'skipLinks', 'service:skipLinks');
    application.inject('service:mystique', 'contentModal', 'service:contentModal');
    application.inject('service:mystique', 'session', 'service:session');
    application.inject('service:mystique', 'viewState', 'service:viewState');
    application.inject('service:mystique', 'orderForm', 'service:orderForm');
    application.inject('service:mystique', 'shoeboxStore', 'service:shoeboxStore');
    application.inject('service:shoeboxStore', 'fastboot', 'service:fastboot');
    application.inject('route', 'mystique', 'service:mystique');
    application.inject('controller', 'mystique', 'service:mystique');
    application.inject('component', 'mystique', 'service:mystique');
  }

  var _default = {
    name: 'mystique',
    initialize: initialize
  };
  _exports.default = _default;
});