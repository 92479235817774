define("bito-shop-frontend/helpers/is-even", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEven = isEven;
  _exports.default = void 0;

  function isEven(params
  /*, hash*/
  ) {
    var number = params[0];
    return number % 2 === 0;
  }

  var _default = Ember.Helper.helper(isEven);

  _exports.default = _default;
});