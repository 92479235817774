define("bito-shop-frontend/components/pager-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a role="button" class="pager__link" {{action setPage pageNumber}}>
    {{pageNumber}}
  </a>
  
  
  */
  {
    "id": "31O5Wk+N",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,\"role\",\"button\"],[24,0,\"pager__link\"],[4,[38,2],[[32,0],[35,1],[35,0]],null],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"pageNumber\",\"setPage\",\"action\"]}",
    "moduleName": "bito-shop-frontend/components/pager-item.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    utils: Ember.inject.service(),
    tagName: 'li',
    classNames: ['pager__item'],
    classNameBindings: ['current:is-active'],
    currentPage: 0,
    // page query parameter
    pageNumber: 1,
    current: Ember.computed('currentPage', function () {
      return Number(this.get('currentPage')) === this.get('pageNumber');
    })
  }));

  _exports.default = _default;
});