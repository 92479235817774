define("bito-shop-frontend/services/shoebox-store", ["exports", "bito-shop-frontend/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments); // setup default store


      this.createStore();
    },
    createStore: function createStore() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _constants.shoeboxID;
      var fastboot = this.fastboot;

      if (fastboot.get('isFastBoot')) {
        fastboot.get('shoebox').put(name, []);
      }
    },
    push: function push(payload) {
      var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _constants.shoeboxID;
      var fb = this.fastboot;

      if (fb.get('isFastBoot')) {
        this.retrieve(name).push(payload);
      }
    },
    retrieve: function retrieve(name) {
      return this.fastboot.get('shoebox').retrieve(name);
    },
    loadInto: function loadInto(store) {
      var fb = this.fastboot;

      if (!fb.get('isFastBoot')) {
        var shoebox = this.retrieve(_constants.shoeboxID) || [];
        shoebox.forEach(function (payload) {
          return store.pushPayload(payload);
        });
      }
    }
  });

  _exports.default = _default;
});