define("bito-shop-frontend/mixins/slider-with-current-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentSlide: 1,
    onSliderResize: function onSliderResize() {
      var sliderInstance = this.get('sliderInstance') || arguments[0]; // return to first slide without triggering callbacks

      if (sliderInstance) {
        sliderInstance.slideTo(0, this.get('sliderConfig.speed'), false);
        var slidesToShow = sliderInstance.params.slidesPerView;
        this.setProperties({
          slidesToShow: slidesToShow,
          slidesToScroll: sliderInstance.params.slidesPerGroup,
          currentSlide: this._calculateCurrentSlide(sliderInstance.activeIndex, slidesToShow)
        });
      }
    },
    totalSlides: Ember.computed('slidesCount', 'slidesToScroll', function () {
      return Math.ceil(this.get('slidesCount') / this.get('slidesToScroll'));
    }),
    showRangeCounter: Ember.computed('totalSlides', function () {
      return this.get('totalSlides') > 1;
    }),
    _calculateCurrentSlide: function _calculateCurrentSlide(activeIndex, slidesToShow) {
      // catch activeIndex for incomplete slide sets
      // and find beginning of next slide set
      while (activeIndex % slidesToShow) {
        ++activeIndex;
      } // convert from zero indexed position to actual slide number


      ++activeIndex;
      return Math.ceil(activeIndex / slidesToShow);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var setCurrentSlide = function setCurrentSlide(sliderInstance) {
        var slidesToShow = sliderInstance.params.slidesPerView;
        var activeIndex = sliderInstance.activeIndex;

        _this.set('currentSlide', _this._calculateCurrentSlide(activeIndex, slidesToShow));
      };

      var onSliderResize = this.get('onSliderResize').bind(this); // on ObserverUpdate is undocumented
      // https://github.com/nolimits4web/Swiper/blob/21d2a1da5b9b2e9610ec4005aad337a4af4d729f/src/js/core.js#L2308

      this.sliderConfig = Ember.assign({}, this.sliderConfig, {
        onSlideChangeEnd: setCurrentSlide,
        onInit: onSliderResize,
        onObserverUpdate: onSliderResize
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});