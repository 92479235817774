define("bito-shop-frontend/constants", ["exports", "bito-shop-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shoeboxID = _exports.localStorageKey = void 0;

  var tenantID = _environment.default.tenantID.split('-')[0];

  var localStorageKey = "".concat(_environment.default.tenant.name, "-").concat(tenantID);
  _exports.localStorageKey = localStorageKey;
  var shoeboxID = 'mystique';
  _exports.shoeboxID = shoeboxID;
});