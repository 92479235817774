define("bito-shop-frontend/mixins/can-display-prices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Expects property 'variant' on consumer to point to a valid variant instance.
  var _default = Ember.Mixin.create({
    hasDisabledOptionValues: false,
    setDisabledOptionValues: function setDisabledOptionValues(val) {
      this.set('hasDisabledOptionValues', val);
    },
    quantity: 1,
    totalQuantity: Ember.computed('variant.packagingUnit', 'quantity', function () {
      var packagingUnit = this.get('variant.packagingUnit') || 1;
      var result = this.get('quantity') * packagingUnit;
      return result;
    }),
    _variantPrice: Ember.computed('variant', 'variant.sortedPrices', 'quantity', function () {
      var variant = this.get('variant');
      var pricePerQuantity = variant.get('pricePerQuantity');
      return pricePerQuantity.call(variant, this.get('quantity'));
    }),
    price: Ember.computed('_variantPrice', function () {
      return this.get('_variantPrice.amount') || 0;
    }),
    totalPrice: Ember.computed('price', 'quantity', function () {
      return this.get('price') * +this.get('quantity');
    }),
    pricePerPiece: Ember.computed('_variantPrice', function () {
      return this.get('_variantPrice.amountPerPiece') || 0;
    }),
    priceGross: Ember.computed('_variantPrice', function () {
      return this.get('_variantPrice.amountGross') || 0;
    }),
    pricePerPiceGross: Ember.computed('_variantPrice', function () {
      return this.get('_variantPrice.amountPerPieceGross') || 0;
    }),
    oldPrice: Ember.computed('_variantPrice', function () {
      return this.get('_variantPrice.oldAmount') || 0;
    }),
    oldPricePerPiece: Ember.computed('_variantPrice', function () {
      return this.get('_variantPrice.oldAmountPerPiece') || 0;
    }),
    isDiscounted: Ember.computed.reads('_variantPrice.isDiscounted'),
    isMegadeal: Ember.computed.reads('_variantPrice.isMegadeal'),
    showAvailableUnits: Ember.computed.reads('_variantPrice.showAvailableUnits'),
    availableUnits: Ember.computed.reads('_variantPrice.availableUnits'),
    percentageSold: Ember.computed.reads('_variantPrice.percentageSold'),
    pricePerPackagingUnit: Ember.computed('variant.packagingUnit', function () {
      return this.get('variant.packagingUnit') > 1;
    })
  });

  _exports.default = _default;
});