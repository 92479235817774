define("bito-shop-frontend/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mmMenu: '(max-width: 1023px)',
    mobile: '(max-width: 640px)',
    // tablet:  '(min-width: 768px) and (max-width: 991px)',
    // desktop: '(min-width: 992px) and (max-width: 1200px)',
    jumboTeaser: '(min-width:1379px)'
  };
  _exports.default = _default;
});