define("bito-shop-frontend/components/bread-crumbs", ["exports", "ember-crumbly/components/bread-crumbs"], function (_exports, _breadCrumbs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.routeHierarchy as |route|}}
    {{#if hasBlock}}
      {{yield this route @labelClass}}
    {{else}}
      <BreadCrumb @route={{route}} @breadCrumbs={{this}} @labelClass={{@labelClass}} />
    {{/if}}
  {{/each}}
  
  
  */
  {
    "id": "y1B6NQsz",
    "block": "{\"symbols\":[\"route\",\"@labelClass\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"routeHierarchy\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,0],[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"bread-crumb\",[],[[\"@route\",\"@breadCrumbs\",\"@labelClass\"],[[32,1],[32,0],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/components/bread-crumbs.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _breadCrumbs.default.reopen({
    breadCrumbClass: null,
    routeHierarchy: Ember.computed('currentUrl', 'currentRouteName', 'reverse', {
      get: function get() {
        var _this$currentRouteNam;

        var currentRouteName = (_this$currentRouteNam = this.currentRouteName) !== null && _this$currentRouteNam !== void 0 ? _this$currentRouteNam : false;
        (false && !(currentRouteName) && Ember.assert('[ember-crumbly] Could not find a curent route', currentRouteName));
        var routeNames = currentRouteName.split('.');
        routeNames.unshift('application');

        var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);

        var crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

        crumbs = [].concat.apply([], crumbs); // flatten 2-dimensional Array

        crumbs.get('lastObject').linkable = false; // make last crumb not linkable by default

        return this.reverse ? crumbs.reverse() : crumbs;
      }
    }).readOnly(),
    _guessRoutePath: function _guessRoutePath(routeNames, name, index) {
      var routes;

      if (index) {
        routes = routeNames.slice(1, index + 1);
      } else {
        routes = routeNames.slice(0, index + 1);
      }

      if (routes.length === 1) {
        var path = "".concat(name, ".index");
        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },
    _lookupBreadCrumb: function _lookupBreadCrumb(routeNames, filteredRouteNames) {
      var _this = this;

      var defaultLinkable = this.linkable; // const pathLength = routeNames.length;
      // const filteredPathLength = filteredRouteNames.length;

      var breadCrumbs = filteredRouteNames.map(function (name, index) {
        var path = _this._guessRoutePath(routeNames, name, index);

        var route = _this._lookupRoute(path); // const crumbLinkable = (index === filteredPathLength - 1) ? false : defaultLinkable;


        var breadCrumb;
        (false && !(route) && Ember.assert("[ember-crumbly] `route:".concat(path, "` was not found"), route));

        if (route.breadCrumbs) {
          breadCrumb = route.breadCrumbs;
        } else {
          var _route$breadCrumb;

          var crumb = (_route$breadCrumb = route.breadCrumb) !== null && _route$breadCrumb !== void 0 ? _route$breadCrumb : {
            title: Ember.String.classify(name)
          };
          breadCrumb = [crumb];
        }

        if (Ember.typeOf(breadCrumb[0]) === 'null') {
          return;
        } else {
          // preserve linkable override from route by setting linkable here if its
          // set on route
          breadCrumb = breadCrumb.map(function (breadCrumb) {
            return Ember.assign({
              path: path,
              linkable: defaultLinkable
            }, breadCrumb);
          });
        }

        return breadCrumb;
      });
      return Ember.A(breadCrumbs.filter(function (breadCrumb) {
        return Ember.typeOf(breadCrumb) !== 'undefined';
      }));
    }
  }));

  _exports.default = _default;
});