define("bito-shop-frontend/components/form-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- TODO: remove guard after address validations is fixed --}}
  
  {{#if @errors.length}}
    {{#each @errors as |error|}}
      <p class="message message--{{@type}} message__icon">{{error.message}}</p>
    {{/each}}
  {{/if}}
  
  */
  {
    "id": "BE7sLwuI",
    "block": "{\"symbols\":[\"error\",\"@type\",\"@errors\"],\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[15,0,[31,[\"message message--\",[32,2],\" message__icon\"]]],[12],[1,[32,1,[\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "bito-shop-frontend/components/form-errors.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});