define("bito-shop-frontend/routes/taxons", ["exports", "jquery", "bito-shop-frontend/routes/base-route"], function (_exports, _jquery, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROOT_TAXON_STEP_ID = 'categories';

  var _default = _baseRoute.default.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      sorting: {
        refreshModel: true
      },
      filters: {
        refreshModel: true
      }
    },
    gtm: Ember.inject.service(),
    mystique: Ember.inject.service(),
    intl: Ember.inject.service(),
    filterService: Ember.inject.service('productFilter'),
    init: function init() {
      this._super.apply(this, arguments);

      this.breadCrumbs = [];
    },
    forceScrollPos: -1,
    hreflangLinks: function hreflangLinks(model) {
      var _this = this;

      var hreflangLinks = model.hreflangLinks && model.hreflangLinks.get('permalinks') || [];
      return hreflangLinks.map(function (_ref) {
        var link = _ref.link,
            locale = _ref.locale;
        return {
          link: _this.mystique.baseUrl() + link,
          locale: locale
        };
      });
    },
    metaDescription: function metaDescription(model) {
      if (!model.taxon.get('metaDescription')) return this.intl.t('taxons.rootTaxonDescription');
      return model.taxon.get('metaDescription');
    },
    model: function model(params) {
      var _this2 = this;

      var filterService = this.filterService;
      var taxonPermalink = params.taxon_permalink.replace(/\/$/, '');
      var taxon = this.store.findRecord('taxon', taxonPermalink);
      var hreflangLinks = null;

      if (this.mystique.tenantConfig.enableHreflangLinks) {
        hreflangLinks = taxon.then(function (taxon) {
          var stepId = taxon.get('stepId') || ROOT_TAXON_STEP_ID;
          return _this2.store.findRecord('hreflangLink', "taxon/".concat(stepId));
        }).catch(console.error);
      }

      var filterStr = params.filters || '{}'; // support old double url encoded filter strings (eg. from bookmarks)

      if (filterStr[0] === '%') filterStr = decodeURIComponent(filterStr);
      var productFilterResult = filterService.getFilteredProducts({
        taxonId: taxonPermalink,
        filters: JSON.parse(filterStr),
        orderBy: params.sorting,
        page: params.page
      });
      return Ember.RSVP.hash({
        taxon: taxon,
        productFilterResult: productFilterResult,
        hreflangLinks: hreflangLinks
      });
    },
    afterModel: function afterModel(model) {
      var breadCrumbs = model.taxon.get('breadcrumbs').sort(function (p1, p2) {
        return p1.permalink.split('/').length > p2.permalink.split('/').length ? 1 : -1;
      });
      this.set('breadCrumbs', breadCrumbs);

      if (model.taxon.metaTitle) {
        this.titleToken = undefined;

        this.title = function title() {
          return model.taxon.metaTitle;
        };
      } else {
        this.title = undefined;

        this.titleToken = function titleToken(model) {
          var taxon = model.taxon;
          var isSubTaxon = Boolean(taxon.id.split('/').length - 1);

          if (isSubTaxon) {
            return this.intl.t('base.taxonBuyOnline', {
              taxon: taxon.name
            });
          } else {
            return this.intl.t('taxons.rootTaxonTitle');
          }
        };
      } // reset forceScrollPos


      this.set('forceScrollPos', -1); // ga4 events

      this.gtm.trackEvent({
        ecommerce: null
      });
      if (model.taxon.get('breadcrumbs')[1]) this.gtm.trackEvent({
        category: model.taxon.get('breadcrumbs')[1].title
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var fb = this.mystique.fastboot;
      var isFb = fb.get('isFastBoot');

      this._refreshAppliedFilters(controller, model.productFilterResult.get('appliedFilters')); // todo: could probably be extracted into service
      // so we can delay this after navigation xhrs have fired


      var pageRequest = this.store.findRecord('page', model.taxon.id).then(function (page) {
        return controller.set('model.page', page), page;
      }).then(function (page) {
        return isFb && page.get('sidebar');
      }).catch(console.error);
      isFb && fb.deferRendering(pageRequest);
    },
    _refreshAppliedFilters: function _refreshAppliedFilters(controller, appliedFilters) {
      // run loop execution order madness
      Ember.run.next(this, function () {
        this.set('queryParams.filters.refreshModel', false);
        this.set('queryParams.filters.replace', true);
        controller.set('filters', JSON.stringify(appliedFilters || {}));

        if (!this.mystique.get('fastboot.isFastBoot')) {
          Ember.run.next(this, function () {
            this.set('queryParams.filters.refreshModel', true);
            this.set('queryParams.filters.replace', false);
          });
        }
      });
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange() {
        if (this.mystique.get('fastboot.isFastBoot')) return;
        var $toolbar = (0, _jquery.default)('.toolbar');
        var currPos = this.get('controller.currentPosition'); // assume currPos === 0 is new pageload

        if (currPos && $toolbar.length) {
          var toolbarOffset = $toolbar.offset().top;
          this.set('forceScrollPos', toolbarOffset);
        }

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});