define("bito-shop-frontend/components/scroll-to-button", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{label}}
  
  */
  {
    "id": "zr08d+yy",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\"]}",
    "moduleName": "bito-shop-frontend/components/scroll-to-button.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'a',
    click: function click() {
      var target = this.get('target');
      var offset = 0;

      if (target) {
        offset = (0, _jquery.default)(target).offset().top;
      }

      (0, _jquery.default)('html,body').animate({
        scrollTop: offset
      }, 300);
    }
  }));

  _exports.default = _default;
});