define("bito-shop-frontend/components/bread-crumb", ["exports", "ember-crumbly/components/bread-crumb"], function (_exports, _breadCrumb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @route.linkable}}
    {{#if @route.permalink}}
      <LinkTo @route={{@route.path}} @model={{@route.permalink}} class={{@linkClass}}>
        {{#if hasBlock}}
          {{yield this @route}}
        {{else}}
          {{@route.title}}
        {{/if}}
      </LinkTo>
    {{else}}
      <LinkTo @route={{@route.path}} class={{@linkClass}}>
        {{#if hasBlock}}
          {{yield this @route}}
        {{else}}
          {{@route.title}}
        {{/if}}
      </LinkTo>
    {{/if}}
  {{else}}
    <span class={{@labelClass}}>
      {{#if hasBlock}}
        {{yield this @route}}
      {{else}}
        {{@route.title}}
      {{/if}}
    </span>
  {{/if}}
  
  
  */
  {
    "id": "lwgeOXk+",
    "block": "{\"symbols\":[\"@route\",\"&default\",\"@labelClass\",\"@linkClass\"],\"statements\":[[6,[37,0],[[32,1,[\"linkable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,1,[\"permalink\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[16,0,[32,4]]],[[\"@route\",\"@model\"],[[32,1,[\"path\"]],[32,1,[\"permalink\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,[[32,0],[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[[16,0,[32,4]]],[[\"@route\"],[[32,1,[\"path\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,[[32,0],[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[32,3]],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[32,0],[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "bito-shop-frontend/components/bread-crumb.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _breadCrumb.default.extend({
    labelClass: ''
  }));

  _exports.default = _default;
});