define("bito-shop-frontend/templates/checkout/processing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P2RIqifz",
    "block": "{\"symbols\":[],\"statements\":[[8,\"checkout-processing\",[],[[\"@model\"],[[32,0,[\"mystique\",\"currentOrder\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/templates/checkout/processing.hbs"
  });

  _exports.default = _default;
});