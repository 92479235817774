define("bito-shop-frontend/lib/parse-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Parse URL strings into URL objects in the Browser and Node.js.
   */
  var parseUrl;

  if (typeof FastBoot !== 'undefined') {
    parseUrl = function (URL) {
      return function (url) {
        return new URL(url);
      };
    }(FastBoot.require('url-parse'));
  } else {
    parseUrl = function (link) {
      return function (url) {
        var result = {};
        link.href = url; // fix insanely stupid bug in some (yes some) IE11 versions

        link.href = link.href; // eslint-disable-line no-self-assign

        result.href = link.href;
        result.protocol = link.protocol;
        result.hostname = link.hostname;
        result.port = link.port;
        result.pathname = link.pathname;
        result.search = link.search;
        result.hash = link.hash;
        result.host = link.host;
        result.origin = link.origin || link.protocol + '//' + link.host;
        return result;
      };
    }(document.createElement('a'));
  }

  var _default = parseUrl;
  _exports.default = _default;
});