define("bito-shop-frontend/components/variant-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each model.badges as |badge|}}
    <div class="badge__item badge__item--text badge__item--{{badge}}">
      <span>{{badge}}</span>
    </div>
  {{/each}}
  
  */
  {
    "id": "n3oeMLiw",
    "block": "{\"symbols\":[\"badge\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"badges\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"badge__item badge__item--text badge__item--\",[32,1]]]],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[32,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/components/variant-badges.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});