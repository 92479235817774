define("bito-shop-frontend/mixins/current-wishlist-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    gtm: Ember.inject.service(),
    wishlistId: null,
    currentWishlist: null,
    accessPermitted: Ember.computed('tenantConfig.wishlist.guestAccess', 'session.isAuthenticated', function () {
      return this.tenantConfig.wishlist.guestAccess || this.get('session.isAuthenticated');
    }),
    addToWishlist: function addToWishlist(variant) {
      var _this = this;

      (false && !(this.get('accessPermitted')) && Ember.assert('addToWishlist() should not be accessible for guests if guestAccess for wishlist is disabled.', this.get('accessPermitted')));
      var currentWishlist = this.get('currentWishlist'); // todo: this is fugly, make nice!

      if (currentWishlist) {
        currentWishlist.get('variants').pushObject(variant);
        return currentWishlist.save().then(function () {
          _this.trigger('didAddToWishlist', variant);

          return variant;
        }).catch(function (err) {
          _this.trigger('addToWishlistFailed', err);

          Ember.RSVP.reject(err);
        });
      }

      return this._createNewWishlist(variant).then(function () {
        _this.trigger('didAddToWishlist', variant);

        return variant;
      }).catch(function (err) {
        _this.trigger('addToWishlistFailed', err);

        Ember.RSVP.reject(err);
      });
    },
    removeFromWishlist: function removeFromWishlist(variant) {
      var _this2 = this;

      (false && !(this.get('accessPermitted')) && Ember.assert('removeFromWishlist() should not be accessible for guests if guestAccess for wishlist is disabled.', this.get('accessPermitted')));
      var currentWishlist = this.get('currentWishlist');

      if (currentWishlist) {
        currentWishlist.get('variants').removeObject(variant);
        return currentWishlist.save().then(function () {
          _this2.trigger('removeFromWishlistSuccess');

          return variant;
        }).catch(function (err) {
          _this2.trigger('removeFromWishlistFailed', err);

          return Ember.RSVP.reject(err);
        });
      }

      return Ember.RSVP.Promise.reject("no wishlist / wishlist is empty");
    },
    isOnWishlist: function isOnWishlist(variant) {
      (false && !(this.get('accessPermitted')) && Ember.assert('isOnWishlist() should not be accessible for guests if guestAccess for wishlist is disabled.', this.get('accessPermitted')));
      var variants = this.get('currentWishlist.variants');

      if (variants) {
        return variants.includes(variant);
      }

      return false;
    },
    _restoreCurrentWishlist: function _restoreCurrentWishlist() {
      var _this3 = this;

      var wishlistId = this.get('wishlistId');

      if (this.tenantConfig.wishlist.guestAccess && wishlistId || this.get('session.isAuthenticated')) {
        var adapter = this.store.adapterFor('wishlist');
        var url = "".concat(adapter.buildURL('wishlist'), "/restore");
        url = wishlistId ? "".concat(url, "?id=").concat(wishlistId) : url;
        return adapter.ajax(url).then(function (response) {
          if (response) {
            _this3.get('store').pushPayload(response);

            var currentWishlist = _this3.get('store').peekRecord('wishlist', response.wishlist.id);

            _this3.set('currentWishlist', currentWishlist);

            _this3.persist({
              wishlistId: currentWishlist.get('id')
            });

            return currentWishlist;
          } else {
            _this3.persist({
              wishlistId: null
            });

            return null;
          }
        }).catch(function (err) {
          _this3.persist({
            wishlistId: null
          });

          _this3.trigger('serverError', err);

          return err;
        });
      } else {
        return Ember.RSVP.resolve(null);
      }
    },
    _createNewWishlist: function _createNewWishlist(variant) {
      var _this4 = this;

      var wishlist = this.store.createRecord('wishlist');

      if (variant) {
        wishlist.get('variants').pushObject(variant);
      }

      return wishlist.save().then(function (newWishlist) {
        _this4.set('currentWishlist', newWishlist);

        _this4.persist({
          guestToken: newWishlist.get('guestToken'),
          wishlistId: newWishlist.get('id')
        });

        return newWishlist;
      });
    },
    trackWishlistAddition: Ember.on('didAddToWishlist', function (variant) {
      // ga4 events
      this.gtm.trackEvent({
        ecommerce: null
      });
      this.gtm.trackEvent({
        event: 'add_to_wishlist',
        ecommerce: {
          items: [{
            item_id: variant.get('sku') || variant.get('stepId'),
            item_name: variant.get('name'),
            currency: variant.get('currency'),
            item_variant: variant.get('executionDescription'),
            price: variant.get('staticPrice')
          }]
        }
      });
      this.gtm.trackEvent({
        'event': 'EventTracking',
        'EventCategory': 'Shopfunktionen',
        'EventAction': 'Im Merkzettel speichern',
        'EventLabel': "".concat(variant.get('name'), " - ").concat(variant.get('sku'))
      });
    })
  });

  _exports.default = _default;
});