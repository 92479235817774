define("bito-shop-frontend/initializers/clean-components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    Ember.Component.reopen({
      tagName: ''
    });
  }

  var _default = {
    name: 'clean-components',
    initialize: initialize
  };
  _exports.default = _default;
});