define("bito-shop-frontend/controllers/virtual-products", ["exports", "bito-shop-frontend/controllers/products"], function (_exports, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _products.default.extend({});

  _exports.default = _default;
});