define("bito-shop-frontend/components/modal-exit-intent", ["exports", "bito-shop-frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a role="button" class="close" {{action "toggleDropdown"}}>
    <XIcon @id="times-solid" @class="icon--medium modal-exit-intent__close-icon" />
  </a>
  <iframe src="https://www.bito.com/de-de/newsletterformular/" height="610" class="modal-exit-intent__iframe">
  </iframe>
  
  */
  {
    "id": "/+zm1Qek",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,\"role\",\"button\"],[24,0,\"close\"],[4,[38,0],[[32,0],\"toggleDropdown\"],null],[12],[2,\"\\n  \"],[8,\"x-icon\",[],[[\"@id\",\"@class\"],[\"times-solid\",\"icon--medium modal-exit-intent__close-icon\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"iframe\"],[14,\"src\",\"https://www.bito.com/de-de/newsletterformular/\"],[14,\"height\",\"610\"],[14,0,\"modal-exit-intent__iframe\"],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "bito-shop-frontend/components/modal-exit-intent.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_modal.default, {
    model: null,
    tagName: 'div',
    classNames: ['layer__inner'],
    classNameBindings: ['isLoading:is-loading'],
    isLoading: false
  }));

  _exports.default = _default;
});