define("bito-shop-frontend/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MVyTEUgy",
    "block": "{\"symbols\":[],\"statements\":[[2,\"404: Ooops, something went wrong...\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/templates/404.hbs"
  });

  _exports.default = _default;
});