define("bito-shop-frontend/templates/pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NcEXE9gE",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"x-hrefs\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"script-eval\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[2,[32,1,[\"content\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/templates/pages.hbs"
  });

  _exports.default = _default;
});