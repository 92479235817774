define("bito-shop-frontend/components/print-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a role="button" {{action "print" bubbles=false}} class="btn btn--arrow">
    <span>{{t "cart.print"}}</span>
  </a>
  
  
  */
  {
    "id": "mTAPPGmm",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,\"role\",\"button\"],[24,0,\"btn btn--arrow\"],[4,[38,0],[[32,0],\"print\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[30,[36,1],[\"cart.print\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "moduleName": "bito-shop-frontend/components/print-button.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    mystique: Ember.inject.service(),
    gtm: Ember.inject.service(),
    actions: {
      print: function print() {
        var order = this.mystique.get('currentOrder');
        this.gtm.trackEvent({
          event: 'EventTracking',
          EventCategory: 'User interaction',
          EventAction: 'Document Print',
          EventLabel: "Print Cart (".concat(order.get('total'), " ").concat(order.get('currency'), ")"),
          EventValue: Math.floor(order.get('total'))
        });
        window.print();
      }
    }
  }));

  _exports.default = _default;
});