define("bito-shop-frontend/templates/password-reset/token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TNcuOTaj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"case__inside\"],[12],[2,\"\\n  \"],[8,\"form-account-new-password\",[],[[\"@token\"],[[34,0,[\"token\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "bito-shop-frontend/templates/password-reset/token.hbs"
  });

  _exports.default = _default;
});