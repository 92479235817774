define("bito-shop-frontend/routes/account/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('account.addresses')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.get('intl').t('account.addresses');
    })
  });

  _exports.default = _default;
});