define("bito-shop-frontend/components/controlled-slider-main", ["exports", "bito-shop-frontend/components/base-slider"], function (_exports, _baseSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield activeSlideIdx slidesToScroll}}
  
  */
  {
    "id": "S152XVQa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,1],[35,0]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"slidesToScroll\",\"activeSlideIdx\"]}",
    "moduleName": "bito-shop-frontend/components/controlled-slider-main.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _baseSlider.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.sliderConfig = {
        observer: true,
        shortSwipes: false,
        longSwipes: false,
        simulateTouch: false,
        allowSwipeToPrev: false,
        allowSwipeToNext: false,
        noSwiping: false
      };
    },
    classNames: ['swiper-container'],
    sliderInstance: null,
    // swiper slider
    setSliderInstance: function setSliderInstance() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('setSliderInstance')(this.get('sliderInstance'));
    }
  }));

  _exports.default = _default;
});