define("bito-shop-frontend/components/product-characteristics-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="boxes__item">
    <div class="boxes__inner">
      <picture class="boxes__image">
        <img src={{src-set model.image.url fm="pjpg" w="270"}}
             sizes="(min-width: 106.25em) calc(((100vw - 210px) / 4) - 250px),
                    (min-width: 78.125em) calc(((100vw - 180px) / 3) - 250px),
                    (min-width: 64em) calc(((100vw - 150px) / 2) - 250px),
                    (min-width: 47.250em) calc(((100vw - 90px) / 2) / 2),
                    (min-width: 37.5em) calc((100vw - 60px) / 2),
                    300px"
             srcset={{src-set model.image.url
                              (hash w="120" descr="120w")
                              (hash w="180" descr="180w")
                              (hash w="300" descr="300w")
                              (hash w="345" descr="345w")
                              (hash w="450" descr="450w")
                              (hash w="570" descr="570w")
                              fm="pjpg"}}
             loading="lazy"
             alt={{model.image.metadata.alt_text}}>
      </picture>
      <div class="boxes__content">
        <h3 class="boxes__headline">{{{model.header}}}</h3>
        <p class="boxes__text">{{{model.description}}}</p>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "zhe15uGM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"boxes__item\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"boxes__inner\"],[12],[2,\"\\n    \"],[10,\"picture\"],[14,0,\"boxes__image\"],[12],[2,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,[36,1],[[35,0,[\"image\",\"url\"]]],[[\"fm\",\"w\"],[\"pjpg\",\"270\"]]]],[14,\"sizes\",\"(min-width: 106.25em) calc(((100vw - 210px) / 4) - 250px),\\n                  (min-width: 78.125em) calc(((100vw - 180px) / 3) - 250px),\\n                  (min-width: 64em) calc(((100vw - 150px) / 2) - 250px),\\n                  (min-width: 47.250em) calc(((100vw - 90px) / 2) / 2),\\n                  (min-width: 37.5em) calc((100vw - 60px) / 2),\\n                  300px\"],[15,\"srcset\",[30,[36,1],[[35,0,[\"image\",\"url\"]],[30,[36,2],null,[[\"w\",\"descr\"],[\"120\",\"120w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"180\",\"180w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"300\",\"300w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"345\",\"345w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"450\",\"450w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"570\",\"570w\"]]]],[[\"fm\"],[\"pjpg\"]]]],[14,\"loading\",\"lazy\"],[15,\"alt\",[34,0,[\"image\",\"metadata\",\"alt_text\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"boxes__content\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"boxes__headline\"],[12],[2,[35,0,[\"header\"]]],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"boxes__text\"],[12],[2,[35,0,[\"description\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"src-set\",\"hash\"]}",
    "moduleName": "bito-shop-frontend/components/product-characteristics-box.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});