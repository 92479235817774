define("bito-shop-frontend/routes/account/index", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('account.label')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.get('intl').t('account.label');
    })
  });

  _exports.default = _default;
});