define("bito-shop-frontend/routes/index", ["exports", "bito-shop-frontend/routes/base-route", "fetch"], function (_exports, _baseRoute, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    mystique: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    prudsys: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.viewState = {
        renderBreadcrumbs: false
      };
    },
    title: function title() {
      if (!this.mystique.tenantConfig.customTitle) return this.intl.t('home.title');
      var currentLocale = this.mystique.get('currentLocale');
      return this.mystique.tenantConfig.customTitle[currentLocale];
    },
    hreflangLinks: function hreflangLinks(model) {
      return model.hreflangLinks;
    },
    metaDescription: function metaDescription() {
      if (!this.mystique.tenantConfig.customMetaDescription) return this.intl.t('home.description');
      var currentLocale = this.mystique.get('currentLocale');
      return this.mystique.tenantConfig.customMetaDescription[currentLocale];
    },
    svcName: Ember.computed.reads('prudsys.client.serviceList.START_PAGE'),
    useRde: Ember.computed('svcName', function () {
      return this.prudsys.isPrudsysActive(this.svcName);
    }),
    model: function model() {
      var _this = this;

      var model = {};
      model.useRde = this.useRde;
      model.svcName = this.svcName; // used by rde to fetch products in top categories

      model.topCategories = this.store.findRecord('taxon-list', 'top_categories'); // data to render fallback content if rde is disabled

      if (!model.useRde) {
        model.otherMainCategories = this.store.findRecord('taxon-list', 'other_main_categories');
        model.topSeller = this.store.findRecord('variant_list', 'top_sellers');
      }

      if (this.mystique.get('fastboot.isFastBoot')) {
        // preload megadeal in fastboot, spa loads data in megadeal component
        model.megaDeals = this.mystique.store.findAll('megaDeal'); // eager load relations, and null out when done

        model._fastBoot = Ember.RSVP.all([model.topCategories.then(function (r) {
          return r.get('taxons');
        }), // topSellers are only set if rde is disabled
        model.topSeller ? model.topSeller.then(function (r) {
          return r.get('variants');
        }) : Promise.resolve()]).then(function () {
          return null;
        });
      }

      model.hreflangLinks = this.mystique.tenantConfig.availableLocales.map(function (locale) {
        return {
          locale: locale,
          link: _this.mystique.baseUrl() + '/' + locale.toLowerCase() + '/'
        };
      });
      model.hreflangLinks.push({
        locale: 'x-default',
        link: this.mystique.baseUrl() + '/'
      });

      if (this.mystique.tenantConfig.hasStaticStartPageHeroUnit) {
        var page = this.store.peekRecord('page', 'start/home-relaunch');
        model.page = Promise.resolve(page).then(function (page) {
          if (page) return page;
          var _this$mystique$env = _this.mystique.env,
              assetRootUrl = _this$mystique$env.assetRootUrl,
              tenantID = _this$mystique$env.tenantID;
          var staticStartPageUrl = "".concat(assetRootUrl, "assets/static-content/").concat(tenantID.toLowerCase(), "/start_home-relaunch.json");
          return (0, _fetch.default)(staticStartPageUrl).then(function (res) {
            return res.json();
          }).then(function (homeRelaunchProperties) {
            _this.store.pushPayload(homeRelaunchProperties);
          }).then(function () {
            return _this.store.peekRecord('page', 'start/home-relaunch');
          });
        });
      } else {
        model.page = this.store.findRecord('page', 'start/home-relaunch').catch(console.warn);
      }

      if (this.mystique.tenantConfig.hasStaticStartPageWelcome) {
        var welcomePage = this.store.peekRecord('page', 'welcome');
        model.welcomePage = Promise.resolve(welcomePage).then(function (welcomePage) {
          if (welcomePage) return welcomePage;
          var assetRootUrl = _this.mystique.env.assetRootUrl;
          var _this$mystique = _this.mystique,
              currentLocale = _this$mystique.currentLocale,
              currentRegion = _this$mystique.currentRegion;
          var staticWelcomePageUrl = "".concat(assetRootUrl, "assets/static-content/").concat(currentLocale, "/").concat(currentRegion, "/welcome.html");
          return (0, _fetch.default)(staticWelcomePageUrl).then(function (res) {
            return res.text();
          }).then(function (content) {
            _this.store.pushPayload({
              page: {
                id: 'welcome',
                sidebar: '',
                type: 'page',
                header: '',
                content: content
              }
            });
          }).then(function () {
            return _this.store.peekRecord('page', 'welcome');
          });
        });
      } else {
        model.welcomePage = this.store.findRecord('page', 'welcome');
      }

      return Ember.RSVP.hash(model);
    }
  });

  _exports.default = _default;
});