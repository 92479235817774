define("bito-shop-frontend/components/page-load-modal", ["exports", "bito-shop-frontend/mixins/can-open-content-modal"], function (_exports, _canOpenContentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_canOpenContentModal.default, {
    cookies: Ember.inject.service(),
    type: '',
    didInsertElement: function didInsertElement() {
      if (!this.get('enabled')) return;
      var cookie = this.mystique.localStorageKey + '-plm';
      var cookieService = this.get('cookies');

      if (!cookieService.read(cookie)) {
        Ember.run.schedule('afterRender', this, function () {
          this.send('openModal', true, this.get('type')); // cookie is set when modal is closed
        });
      }
    }
  });

  _exports.default = _default;
});