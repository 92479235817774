define("bito-shop-frontend/services/faye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    mystique: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var FAYE_URL = this.get('mystique.env.fayeURL');
      this.set('client', new window.Faye.Client(FAYE_URL));
    }
  });

  _exports.default = _default;
});