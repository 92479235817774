define("bito-shop-frontend/services/content-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.options = this.options || {};
    },
    model: null,
    modalType: 'variant',
    skipLinks: Ember.inject.service(),
    registerComponent: function registerComponent(component) {
      this.component = component;
    },
    openModal: function openModal(model, modalType) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.setProperties({
        model: model,
        modalType: modalType,
        options: options
      });
      this.get('skipLinks').activate(this.component);
    },
    closeModal: function closeModal() {
      this.get('skipLinks').deactivate();
    }
  });

  _exports.default = _default;
});