define("bito-shop-frontend/routes/search", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var searchTypes = ['productSearch', 'categorySearch'];
  var stLut = ['p', 'c'];
  var listName = 'search';

  var _default = _baseRoute.default.extend({
    queryParams: {
      query: {
        refreshModel: true
      },
      searchType: {
        refreshModel: true
      }
    },
    mystique: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    gtm: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.intl.t('base.search')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.intl.t('base.search');
    }),
    model: function model(params) {
      var _this = this;

      var search = params.query;
      var selectedTypes = params.searchTypes && params.searchTypes.split(',');
      selectedTypes = selectedTypes ? selectedTypes.reduce(function (mem, st) {
        return stLut.includes(st) ? (mem.push(searchTypes[stLut.indexOf(st)]), mem) : mem;
      }, []) : searchTypes;
      var model = selectedTypes.reduce(function (obj, s) {
        obj[s] = _this.store.query(s, {
          search: search
        }).then(function (res) {
          return res.get('firstObject');
        });
        return obj;
      }, {});
      model.sidebar = this.store.findRecord('cms-element', 'sidebar/kategorien').then(function (element) {
        var content = element.get('content');

        if (content.length > 1) {
          return content[Math.floor(Math.random() * content.length)];
        } else {
          return content[0];
        }
      }).catch(function ()
      /* err */
      {
        return '';
      });
      return Ember.RSVP.hash(model);
    },
    actions: {
      trackGTMClick: function trackGTMClick(item, index) {
        // ga4 events
        this.gtm.trackEvent({
          ecommerce: null
        });
        this.gtm.trackEvent({
          event: 'select_item',
          ecommerce: {
            items: [{
              item_id: item.get('metadata.sku'),
              item_name: item.get('title'),
              item_variant: item.get('description'),
              item_list_id: listName,
              price: item.get('metadata.prices.firstObject.amount'),
              index: index,
              quantity: 1
            }]
          }
        });
        this.gtm.trackEvent({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: {
                list: listName
              },
              products: [{
                name: item.get('title'),
                id: item.get('metadata.sku'),
                price: item.get('metadata.prices.firstObject.amount'),
                variant: item.get('description'),
                position: index + 1
              }]
            }
          }
        });
      },
      didTransition: function didTransition() {
        var searchResults = this.currentModel.productSearch.get('searchResults');
        var searchTerm = this.paramsFor(this.routeName).query;
        this.mystique.trigger('searchTerm', searchTerm);

        if (searchResults.get('length')) {
          // ga4 events
          this.gtm.trackEvent({
            ecommerce: null
          });
          this.gtm.trackEvent({
            event: 'view_item_list',
            ecommerce: {
              items: searchResults.map(function (item, index) {
                return {
                  item_id: item.get('metadata.sku'),
                  item_name: item.get('title'),
                  item_variant: item.get('description'),
                  item_list_id: listName,
                  price: item.get('metadata.prices.firstObject.amount'),
                  index: index,
                  quantity: 1
                };
              })
            }
          });
          var impressions = searchResults.map(function (item, index) {
            return {
              name: item.get('title'),
              id: item.get('metadata.sku'),
              variant: item.get('description'),
              price: item.get('metadata.prices.firstObject.amount'),
              list: listName,
              position: index + 1
            };
          });
          this.gtm.trackEvent({
            ecommerce: {
              currencyCode: undefined,
              impressions: impressions
            }
          });
        } else {
          // ga4 events
          this.gtm.trackEvent({
            ecommerce: null
          });
          this.gtm.trackEvent({
            event: 'view_item_list',
            ecommerce: {
              item_list_id: listName,
              items: []
            }
          });
          this.gtm.trackEvent({
            event: 'EventTracking',
            EventCategory: 'Search',
            EventAction: 'No results',
            EventLabel: this.currentModel.productSearch.get('userQuery')
          });
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});