define("bito-shop-frontend/services/checkouts", ["exports", "ember-fsm"], function (_exports, _emberFsm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The checkouts service is a Stateful global for progressing an Order through
    the Spree checkout, via the Checkouts endpoint.  It also serves as the
    "canonical" state for a Checkout frontend, and should be used to progress
    forward and backward through an order's steps.
  
    It's designed to allow a reactive programming style for the checkout
    frontend.  The `spree-checkout` component from the Spree Ember Storefronts
    package is an example of this style.
  
    ```javascript
    var checkouts = this.get('spree.checkouts');
  
    checkouts.get('currentState');
    // => "address"
  
    checkouts.transition().then(function() {
      checkouts.get('currentOrder.state');
      // => "delivery"
      checkouts.get('currentState');
      // => "delivery"
  
      checkouts.transition("address").then(function() {
  
        // Here, the Checkout and Current Order state has diverged.  This is
        // expected behaviour, and their states will reconcile when the
        // checkout progresses forward again.
  
        checkouts.get('currentOrder.state');
        // => "delivery"
        checkouts.get('currentState');
        // => "address"
      });
    });
    ```
  
    @class Checkouts
    @namespace Service
    @uses Ember.FSM.Stateful
  */
  var _default = Ember.Service.extend(_emberFsm.default.Stateful, {
    /**
      A reference to the Spree Service.
       @property spree
      @type Ember.Service
    */
    mystique: Ember.inject.service(),
    spree: Ember.computed.alias('mystique'),
    // TODO: replace

    /**
      A reference to the Spree Object's `currentOrder`.
       @property currentOrder
      @type DS.Model
    */
    currentOrder: Ember.computed.alias('spree.currentOrder'),

    /**
      Provided by Ember FSM, the current state of the checkout. This will often
      diverge from the `currentOrder.get('state')`, and will be reconsolidated when
      the state machine calls `validateOrder`. This is what we use as the
      cononical "state" for a frontend's Checkout flow.
       @property currentState
      @type String
      @default "idle"
    */
    currentState: Ember.computed.alias('fsmCurrentState'),

    /**
      Provided by Ember FSM, returns true when there's an active transition.  Handy
      for showing customers loading states during the checkout.
       @property isLoading
      @type Boolean
      @default false
    */
    isLoading: Ember.computed.alias('fsmIsLoading'),
    init: function init() {
      this.fsmStates = {
        initialState: 'idle',
        knownStates: ['idle', 'cart', 'address', 'delivery', 'payment', 'confirm', 'processing', 'complete', 'failed']
      };
      this.fsmEvents = {
        toCart: {
          transitions: [{
            from: ['$initial', 'failed'],
            to: 'cart'
          }, {
            from: ['address', 'delivery', 'payment', 'confirm', 'processing'],
            to: 'cart',
            didEnter: '_reconcileOrderState'
          }]
        },
        toAddress: {
          after: '_ensureBillAddressExists',
          transitions: [{
            from: ['cart'],
            to: 'address',
            willEnter: '_validateOrder'
          }, {
            from: ['$initial', 'delivery', 'payment', 'confirm', 'processing', 'failed'],
            to: 'address',
            didEnter: '_reconcileOrderState'
          }]
        },
        toDelivery: {
          transitions: [{
            from: ['address'],
            to: 'delivery',
            willEnter: '_registerUserAndValidateOrder'
          }, {
            from: ['$initial', 'payment', 'confirm', 'failed'],
            to: 'delivery',
            didEnter: '_reconcileOrderState'
          }]
        },
        toPayment: {
          after: '_ensureNewPaymentExists',
          transitions: [{
            from: ['cart', 'address', 'delivery'],
            to: 'payment',
            willEnter: '_validateOrder'
          }, {
            from: ['$initial', 'confirm', 'processing', 'failed'],
            to: 'payment',
            didEnter: '_reconcileOrderState'
          }]
        },
        toConfirm: {
          transitions: [{
            from: ['address', 'delivery', 'payment'],
            to: 'confirm',
            willEnter: '_validateOrder'
          }, {
            from: ['$initial', 'failed'],
            to: 'confirm'
          }, {
            from: ['processing'],
            to: 'confirm',
            didEnter: '_reconcileOrderState'
          }]
        },
        toProcessing: {
          transitions: [{
            from: ['confirm'],
            to: 'processing',
            willEnter: ['_validateOrder']
          }, {
            from: ['failed'],
            to: 'processing'
          }]
        },
        toComplete: {
          after: '_currentOrderDidComplete',
          transitions: [{
            from: ['confirm', 'processing'],
            to: 'complete',
            willEnter: '_validateOrder'
          }]
        },
        toIdle: {
          transition: {
            $all: 'idle'
          }
        },
        error: {
          after: '_resetStateMachine',
          transition: {
            $all: 'failed'
          }
        }
      };

      this._super.apply(this, arguments);
    },
    _currentOrderDidComplete: function _currentOrderDidComplete() {
      this.get('mystique').trigger('currentOrderDidComplete', this.get('currentOrder'));
    },
    _reconcileOrderState: function _reconcileOrderState() {
      this.set('currentOrder.state', this.get('currentState'));
    },

    /**
      If a state name is passed to this method, the state machine will attempt to
      transition directly to that state.  If not, we will attempt to transition
      to the next state in the checkout flow.
       @method transition
      @param {String} stateName Optional, a state to attempt transition to.
      @return {Ember.RSVP.Promise} A promise that resolves when the State Machine
      resolves it's transition.
    */
    transition: function transition(stateName) {
      var checkoutState = this.get('currentState');
      var currentOrder = this.get('currentOrder');
      var nextStateName = '';

      if (currentOrder) {
        if (stateName) {
          if (stateName === checkoutState) {
            return Ember.RSVP.resolve(stateName);
          }

          nextStateName = stateName;
        } else {
          // _resetStateMachine
          if (checkoutState === "failed") {
            nextStateName = currentOrder.get('state');
          } else {
            var allStates = currentOrder.get('checkoutSteps');
            nextStateName = allStates[allStates.indexOf(checkoutState) + 1];
          }
        }
      } else {
        nextStateName = 'idle';
      }

      var stateAction = "to" + Ember.String.capitalize(nextStateName);
      return this.sendStateEvent(stateAction);
    },

    /**
      Called whenever the State Machine enters "Address".  Ensures that an address
      object exists for the order's `billAddress`.
       @method _ensureBillAddressExists
      @private
    */
    _ensureBillAddressExists: function _ensureBillAddressExists() {
      var _this2 = this;

      this.get('currentOrder.billAddress').then(function (billAddress) {
        if (!billAddress) {
          var newBillAddress = _this2.get('spree').store.createRecord('address');

          _this2.set('currentOrder.billAddress', newBillAddress);
        }
      });
    },

    /**
      Called whenever the State Machine enters "Payment". Ensures that a fresh
      payment for the `currentOrder` exists.
       @method _ensureNewPaymentExists
      @private
    */
    _ensureNewPaymentExists: function _ensureNewPaymentExists() {
      var currentOrder = this.get('currentOrder');
      var payments = currentOrder.get('payments');

      if (Ember.isEmpty(payments) || !payments.get('lastObject.isNew')) {
        var newPayment = this.get('spree').store.createRecord('payment');
        var paymentMethods = currentOrder.get('availablePaymentMethods');
        var newPaymentMethod = currentOrder.get('activePayment.paymentMethod');
        newPaymentMethod = newPaymentMethod && paymentMethods.mapBy('name').includes(newPaymentMethod.get('name')) ? newPaymentMethod : paymentMethods.get('firstObject');
        newPayment.set('paymentMethod', newPaymentMethod);
        payments.pushObject(newPayment);
      }
    },

    /**
      When `validateOrder` fails, the State Machine will automatically transition
      to "failed".  Here, we listen to that transition, and then automatically
      run this method, to reset the State Machine to the State of the
      `currentOrder`.
       Note: Because Ember QUnit relies on returned promise for Unit tests, we
      disable this method in `test`, and manually reset the State Machine when
      testing failed transitions.
       @method _resetStateMachine
      @private
    */
    _resetStateMachine: function _resetStateMachine() {
      return this.transition();
    },

    /**
      Used to save changes to the `currentOrder` to Spree's Checkouts API.  Is only
      called by the state machine when advancing to a later state in the flow.
       @method _validateOrder
      @private
      @param {Transition} transition The current state machine transition.
      @return {Ember.RSVP.Promise} A promise that resolves when the server
      response returns.
    */
    _validateOrder: function _validateOrder(transition) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('currentOrder').saveToCheckouts().then(function (currentOrder) {
          if (currentOrder.get('state') === transition.toState) {
            resolve(currentOrder);
          } else {
            reject();
          }
        }, function () {
          reject();
        });
      });
    },
    _registerUserAndValidateOrder: function _registerUserAndValidateOrder(transition) {
      var _this3 = this;

      if (this.get('mystique.isRegisterCheckout')) {
        return this._registerUser().then(function () {
          return _this3._validateOrder(transition);
        });
      }

      return this._validateOrder(transition);
    },
    _registerUser: function _registerUser() {
      var _this4 = this;

      return this.get('mystique.newUser').save().then(function (user) {
        var loginConfig = user.getProperties('email', 'password');
        loginConfig.disableTransition = true;
        return _this4.get('mystique').loginUser(loginConfig);
      });
    },
    openPaymentWindow: function openPaymentWindow() {
      if (this.get('currentOrder.checkoutSteps').includes('processing')) {
        window.paymentWindow = window.open("/".concat(this.get('mystique.localePrefix'), "/payment.html"), 'paymentWindow');
      }
    },
    closePaymentWindow: function closePaymentWindow() {
      if (window.paymentWindow) {
        window.paymentWindow.close();
        window.paymentWindow = null;
      }
    }
  });

  _exports.default = _default;
});