define("bito-shop-frontend/components/x-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{svg-jar (concat "#" @id) class=(unless @noClassDefault (concat "icon " @class) @class)}}
  
  
  */
  {
    "id": "1xz7+1qa",
    "block": "{\"symbols\":[\"@class\",\"@noClassDefault\",\"@id\"],\"statements\":[[1,[30,[36,2],[[30,[36,0],[\"#\",[32,3]],null]],[[\"class\"],[[30,[36,1],[[32,2],[30,[36,0],[\"icon \",[32,1]],null],[32,1]],null]]]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"unless\",\"svg-jar\"]}",
    "moduleName": "bito-shop-frontend/components/x-icon.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});