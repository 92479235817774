define("bito-shop-frontend/components/modal-page", ["exports", "bito-shop-frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a role="button" class="close" {{action "closeModal"}}>
    <span class="close__txt">{{t "base.closeMenu"}}</span>
    <XIcon @id="times-solid" @class="icon--huge" />
  </a>
  
  {{{model.content}}}
  
  
  */
  {
    "id": "VE9w4Twr",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,\"role\",\"button\"],[24,0,\"close\"],[4,[38,0],[[32,0],\"closeModal\"],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"close__txt\"],[12],[1,[30,[36,1],[\"base.closeMenu\"],null]],[13],[2,\"\\n  \"],[8,\"x-icon\",[],[[\"@id\",\"@class\"],[\"times-solid\",\"icon--huge\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[2,[35,2,[\"content\"]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"model\"]}",
    "moduleName": "bito-shop-frontend/components/modal-page.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_modal.default, {
    tagName: 'div',
    classNames: ['layer__inner']
  }));

  _exports.default = _default;
});