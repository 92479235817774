define("bito-shop-frontend/routes/wishlist", ["exports", "bito-shop-frontend/routes/base-route", "ember-simple-auth/configuration"], function (_exports, _baseRoute, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('account.wishlist')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.get('intl').t('account.wishlist');
    }),
    beforeModel: function beforeModel(transition) {
      if (this.mystique.tenantConfig.wishlist.guestAccess) {
        this._super.apply(this, arguments);
      } else {
        this.mystique.get('session').requireAuthentication(transition, 'login');
      }
    }
  });

  _exports.default = _default;
});