define("bito-shop-frontend/helpers/cdn-src", ["exports", "bito-shop-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cdnSrc = cdnSrc;
  _exports.default = void 0;

  /** @type {(params: string[]) => string} */
  function cdnSrc(params
  /*, hash*/
  ) {
    var imageName = params.join('');
    return imageName ? _environment.default.tenant.cdnURL + imageName : null;
  }

  var _default = Ember.Helper.helper(cdnSrc);

  _exports.default = _default;
});