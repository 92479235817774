define("bito-shop-frontend/initializers/gtm", ["exports", "bito-shop-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    var GOOGLE_ANALYTICS_ID = _environment.default.mysEnvironment === 'staging' ? _environment.default.tenant.googleAnalyticsIDStaging : _environment.default.tenant.googleAnalyticsID;
    window.gtmConfig = Ember.Object.create();
    window.dataLayer = [{
      CustomerAnalyticsID: GOOGLE_ANALYTICS_ID
    }];

    if (typeof FastBoot === 'undefined' && !(_environment.default.gtm.enabled && _environment.default.gtm.containerId && GOOGLE_ANALYTICS_ID)) {
      window.ga = Function.prototype;
    }
  }

  var _default = {
    name: 'gtm',
    initialize: initialize
  };
  _exports.default = _default;
});