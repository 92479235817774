define("bito-shop-frontend/templates/account/address/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/PLJUz/d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"panel pattern--gray\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"grid grid--max\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"headline-group text-center grid__col-24\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"account.addNewAddress\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"pattern--horizon\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"unit-wrapper\"],[12],[2,\"\\n    \"],[8,\"account-menu\",[],[[],[]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"unit unit--main\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"account\"],[12],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"grid grid--nested grid--max\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"grid__col-24\"],[12],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"case\"],[12],[2,\"\\n              \"],[10,\"div\"],[14,0,\"case__inside headline-group flex-wrap\"],[12],[2,\"\\n                \"],[6,[37,1],null,[[\"class\",\"route\"],[\"link link__back\",\"account.address\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"base.backToOverview\"],null]]],\"parameters\":[]}]]],[2,\"\\n              \"],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"case__inside\"],[12],[2,\"\\n                \"],[8,\"account-form-address\",[],[[\"@model\",\"@isDefault\",\"@onSuccess\"],[[34,2],[30,[36,3],[[35,2,[\"address\",\"id\"]],[35,2,[\"account\",\"defaultAddress\",\"id\"]]],null],[30,[36,4],[\"onSave\"],null]]],null],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"model\",\"eq\",\"route-action\"]}",
    "moduleName": "bito-shop-frontend/templates/account/address/new.hbs"
  });

  _exports.default = _default;
});