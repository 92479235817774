define("bito-shop-frontend/mixins/object-fit-polyfill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mixin = {};

  if (window.objectFitPolyfill) {
    mixin = {
      didRender: function didRender() {
        this._super.apply(this, arguments);

        window.objectFitPolyfill();
      }
    };
  }

  var _default = Ember.Mixin.create(mixin);

  _exports.default = _default;
});