define("bito-shop-frontend/components/sub-taxons", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel pattern--gray">
    <div class="grid grid--max">
      <div class="headline-group grid__col-24">
        <h1>{{taxonName}}</h1>
      </div>
      <div class="grid__col-24">
        <CategorySlider @collection={{sortedSubtaxons}} @filters={{filters}} />
      </div>
    </div>
  </div>
  
  */
  {
    "id": "fxZlRzvq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"panel pattern--gray\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"grid grid--max\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"headline-group grid__col-24\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[34,0]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid__col-24\"],[12],[2,\"\\n      \"],[8,\"category-slider\",[],[[\"@collection\",\"@filters\"],[[34,1],[34,2]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"taxonName\",\"sortedSubtaxons\",\"filters\"]}",
    "moduleName": "bito-shop-frontend/components/sub-taxons.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    sortedSubtaxons: Ember.computed('subTaxons', 'topSubCategories', function () {
      var topSubCategories = this.get('topSubCategories');

      if (topSubCategories && topSubCategories.length) {
        var sortedSubTaxons = this.get('subTaxons').then(function (subTaxons) {
          subTaxons = subTaxons.toArray();
          return subTaxons.sort(function (t1, t2) {
            var idx1 = topSubCategories.indexOf(topSubCategories.find(function (t) {
              return t.UID == t1.get('stepId');
            }));
            var idx2 = topSubCategories.indexOf(topSubCategories.find(function (t) {
              return t.UID == t2.get('stepId');
            }));

            if (idx1 == -1 && idx2 == -1) {
              return 0;
            } else if (idx1 == -1) {
              return 1;
            } else if (idx2 == -1) {
              return -1;
            } else {
              return idx1 - idx2;
            }
          });
        }).then(function (sortedSubTaxons) {
          sortedSubTaxons.forEach(function (t) {
            var tc = topSubCategories.find(function (tc) {
              return tc.UID == t.get('stepId');
            });
            if (tc) t.set('rdeToken', tc.trackingToken);
          });
          return sortedSubTaxons;
        });
        return _emberData.default.PromiseArray.create({
          promise: sortedSubTaxons
        });
      } else {
        return this.get('subTaxons');
      }
    })
  }));

  _exports.default = _default;
});