define("bito-shop-frontend/components/cms-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "k2fUuP4a",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/components/cms-link.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'target', 'rel'],
    newTab: false,
    target: Ember.computed('newTab', function () {
      if (this.get('newTab')) return '_blank';else return null;
    }),
    rel: Ember.computed('newTab', function () {
      if (this.get('newTab')) return 'noopener';else return null;
    })
  }));

  _exports.default = _default;
});