define("bito-shop-frontend/mixins/variant-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    variant: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var model = this.get('model');

      if (model) {
        this.set('isLoading', true);
        model.get('product').then(function ()
        /* product */
        {
          // model is an variant in lineitem copy feature
          return model.get('variant') || model;
        }).then(function (variant) {
          _this.setProperties({
            isLoading: false,
            variant: variant
          });
        });
      }
    }
  });

  _exports.default = _default;
});