define("bito-shop-frontend/routes/password-reset/token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('base.setNewPassword')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.get('intl').t('base.setNewPassword');
    }),
    model: function model(params) {
      return {
        token: params['token']
      };
    }
  });

  _exports.default = _default;
});