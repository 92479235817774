define("bito-shop-frontend/mixins/can-update-view-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments);

      var viewStateDefaults = this.mystique.viewState.get('defaults');
      var newViewState = Object.assign({}, viewStateDefaults, this.get('viewState'));
      this.mystique.viewState.setProperties(newViewState);
    }
  });

  _exports.default = _default;
});