define("bito-shop-frontend/routes/pages", ["exports", "bito-shop-frontend/routes/base-route", "fetch"], function (_exports, _baseRoute, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _baseRoute.default.extend({
    titleToken: function titleToken(model) {
      return model.get('title');
    },
    model: function model(params) {
      var _this = this;

      var pagePermalink = params.page_id.replace(/\/$/, '');

      if (this.mystique.tenantConfig.hasStaticContentPages) {
        var pageContent = this.mystique.store.peekRecord('page', pagePermalink);
        if (pageContent) return pageContent;
        var _this$mystique$env = this.mystique.env,
            assetRootUrl = _this$mystique$env.assetRootUrl,
            tenantID = _this$mystique$env.tenantID;
        var pageName = pagePermalink.toLowerCase().replace(/\//, '_');
        var staticNavItemsUrl = "".concat(assetRootUrl, "assets/static-content/").concat(tenantID.toLowerCase(), "/c/").concat(pageName, ".html");
        return (0, _fetch.default)(staticNavItemsUrl).then(function (res) {
          return res.text();
        }).then(function (html) {
          _this.mystique.store.pushPayload({
            page: {
              id: pagePermalink,
              type: 'page',
              content: html
            }
          });
        }).then(function () {
          return _this.mystique.store.peekRecord('page', pagePermalink);
        });
      }

      return this.store.findRecord('page', pagePermalink);
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumbs', [].concat(_toConsumableArray(model.get('parents')), [{
        permalink: model.get('id'),
        title: model.get('title')
      }]));
    }
  });

  _exports.default = _default;
});