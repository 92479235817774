define("bito-shop-frontend/templates/checkout/delivery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t8AGk+du",
    "block": "{\"symbols\":[],\"statements\":[[8,\"checkout-delivery\",[],[[\"@shipments\"],[[32,0,[\"mystique\",\"currentOrder\",\"shipments\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/templates/checkout/delivery.hbs"
  });

  _exports.default = _default;
});