define("bito-shop-frontend/components/toggle-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="delivery">
    <h3>{{t "account.shippingAddress"}}</h3>
  
    <div class="pick pick--frame">
      <RadioButton @value={{true}}
                   @groupValue={{useBilling}}
                   @changed="toggleBillAddress"
                   @radioId="delivery[1]" />
  
      <label for="delivery[1]">{{t "checkout.sameShippingAddress"}}</label>
    </div>
  
    <div class="pick pick--frame">
      <RadioButton @value={{false}}
                   @groupValue={{useBilling}}
                   @changed="toggleBillAddress"
                   @radioId="delivery[2]" />
  
      <label for="delivery[2]">{{t "checkout.divergentShippingAddress"}}</label>
    </div>
  </div>
  
  */
  {
    "id": "M5mU2xb4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"delivery\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"account.shippingAddress\"],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"pick pick--frame\"],[12],[2,\"\\n    \"],[8,\"radio-button\",[],[[\"@value\",\"@groupValue\",\"@changed\",\"@radioId\"],[true,[34,1],\"toggleBillAddress\",\"delivery[1]\"]],null],[2,\"\\n\\n    \"],[10,\"label\"],[14,\"for\",\"delivery[1]\"],[12],[1,[30,[36,0],[\"checkout.sameShippingAddress\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"pick pick--frame\"],[12],[2,\"\\n    \"],[8,\"radio-button\",[],[[\"@value\",\"@groupValue\",\"@changed\",\"@radioId\"],[false,[34,1],\"toggleBillAddress\",\"delivery[2]\"]],null],[2,\"\\n\\n    \"],[10,\"label\"],[14,\"for\",\"delivery[2]\"],[12],[1,[30,[36,0],[\"checkout.divergentShippingAddress\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"useBilling\"]}",
    "moduleName": "bito-shop-frontend/components/toggle-address.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    store: Ember.inject.service(),
    useBilling: true,
    // mystique.currentOrder.useBilling
    countries: null,
    cleanupShippingAddress: function cleanupShippingAddress() {
      this.mystique.set('currentOrder.shipAddress', this.mystique.get('currentOrder.billAddress'));
    },
    ensureShippingAddressExists: function ensureShippingAddressExists() {
      var newShipAddress = this.get('store').createRecord('address', {
        country: this.get('countries').findBy('iso', this.mystique.get('tenantConfig.defaultCountry'))
      });
      this.mystique.set('currentOrder.shipAddress', newShipAddress);
    },
    actions: {
      toggleBillAddress: function toggleBillAddress(useBilling) {
        if (useBilling) {
          this.cleanupShippingAddress();
        } else {
          this.ensureShippingAddressExists();
        }
      }
    }
  }));

  _exports.default = _default;
});