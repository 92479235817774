define("bito-shop-frontend/mixins/slider-with-gtm-tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    mystique: Ember.inject.service(),
    gtm: Ember.inject.service(),
    _getGtmItems: function _getGtmItems(from, to) {
      return Ember.RSVP.resolve(this.get('collection')).then(function () {
        var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        return Ember.RSVP.Promise.all(items.slice(from, to));
      });
    },
    // should we update tracking on resize?
    submitImpressions: Ember.observer('activeSlideIdx', 'sliderInstance', function () {
      var _this = this;

      var showFrom = this.get('activeSlideIdx');
      var showTo = showFrom + this.get('sliderInstance.params.slidesPerView');

      this._getGtmItems(showFrom, showTo).then(function (items) {
        Promise.all(items.map(function (sliderItem) {
          return sliderItem === null || sliderItem === void 0 ? void 0 : sliderItem.priceGroup;
        })).then(function () {
          var impressions = items.map(function (item, index) {
            item = item.get && item.get('metadata') || item;
            return {
              name: Ember.get(item, 'name'),
              id: Ember.get(item, 'sku'),
              price: Ember.get(item, 'price') || Ember.get(item, 'min_price_per_piece'),
              list: _this.get('list'),
              position: index + 1 + showFrom
            };
          });
          if (!impressions.length) return;

          var currencyCode = _this.get('collection').get('firstObject.metadata.currency'); // ga4 events


          _this.gtm.trackEvent({
            ecommerce: null
          });

          _this.gtm.trackEvent({
            event: 'view_item_list',
            ecommerce: {
              items: items.map(function (item, index) {
                item = item.get && item.get('metadata') || item;
                return {
                  item_id: Ember.get(item, 'sku'),
                  item_name: Ember.get(item, 'name'),
                  item_variant: Ember.get(item, 'executionDescription'),
                  price: Ember.get(item, 'price') || Ember.get(item, 'min_price_per_piece'),
                  item_list_id: _this.get('list'),
                  index: index + showFrom,
                  quantity: 1
                };
              })
            }
          });

          _this.gtm.trackEvent({
            ecommerce: {
              currencyCode: currencyCode,
              impressions: impressions
            }
          });
        });
      });
    })
  });

  _exports.default = _default;
});