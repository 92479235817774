define("bito-shop-frontend/templates/checkout/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gmlZfy5Z",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"checkout-complete\",[],[[\"@model\",\"@order\",\"@shipAddress\"],[[32,1],[32,0,[\"mystique\",\"currentOrder\"]],[32,0,[\"mystique\",\"currentOrder\",\"shipAddress\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/templates/checkout/complete.hbs"
  });

  _exports.default = _default;
});