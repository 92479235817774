define("bito-shop-frontend/mixins/slider-with-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this.sliderConfig = Ember.assign({}, this.sliderConfig, {
        pagination: '.js-slider-pagination',
        paginationClickable: true
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});