define("bito-shop-frontend/components/base-slider", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.sliderConfig = this.sliderConfig || {};
    },
    tagName: 'div',
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesCount: Ember.computed.reads('collection.length'),
    sliderInstance: null,
    activeSlideIdx: 0,
    // updated in onSlideChangeStart()
    singleSlideCallback: null,
    // Function.prototype
    forceInit: false,
    sliderIsActive: Ember.computed.gt('totalSlides', 1),
    sliderSelector: Ember.computed(function () {
      return '#' + this.get('elementId');
    }),
    totalSlides: Ember.computed('slidesCount', 'slidesToScroll', function () {
      return Math.ceil(this.get('slidesCount') / this.get('slidesToScroll'));
    }),
    initSlider: function initSlider() {
      var slidesCount = this.get('slidesCount');
      var hasNoSlides = slidesCount <= this.get('slidesToShow');
      var hasNoBreakpoints = !this.get('sliderConfig.breakpoints');

      if (!this.get('forceInit') && hasNoBreakpoints && hasNoSlides && this.singleSlideCallback) {
        this.singleSlideCallback();
      } else {
        var swiper = new window.Swiper(this.get('sliderSelector'), this.sliderConfig);
        this.set('sliderInstance', swiper);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.sliderConfig = Object.assign({}, {
        slidesPerView: this.get('slidesToShow'),
        slidesPerGroup: this.get('slidesToScroll'),
        onSlideChangeStart: function onSlideChangeStart(swiper) {
          return _this.set('activeSlideIdx', swiper.activeIndex);
        }
      }, this.sliderConfig);
      this.initSlider();

      if (this.onSliderResize) {
        var debouncedSliderResize = this.set('debouncedSliderResize', function () {
          Ember.run.debounce(_this, _this.onSliderResize, 200);
        });
        (0, _jquery.default)(window).on('resize', debouncedSliderResize);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var sliderInstance = this.get('sliderInstance');

      if (sliderInstance && sliderInstance.destroy) {
        sliderInstance.destroy();
      }

      if (this.onSliderResize) {
        (0, _jquery.default)(window).off('resize', this.debouncedSliderResize);
      }
    }
  });

  _exports.default = _default;
});