define("bito-shop-frontend/routes/checkout/payment", ["exports", "ember-scroll-operator/mixins/scroll-operator", "bito-shop-frontend/routes/checkout-step"], function (_exports, _scrollOperator, _checkoutStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _checkoutStep.default.extend(_scrollOperator.default, {});

  _exports.default = _default;
});