define("bito-shop-frontend/helpers/image-url", ["exports", "bito-shop-frontend/helpers/cdn-src"], function (_exports, _cdnSrc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.imageUrl = imageUrl;
  _exports.default = void 0;

  function imageUrl(params, namedArgs) {
    var url = params[0]; // dummy image

    if (!url) {
      url = (0, _cdnSrc.cdnSrc)(['global/fallback-product-images.png']);
    }

    var imageServerConfig = {
      w: namedArgs.width || 100,
      h: namedArgs.height || 100,
      chromasub: namedArgs.chromasub,
      fm: namedArgs.format,
      q: namedArgs.quality,
      fit: namedArgs.fit,
      crop: namedArgs.crop,
      extend: namedArgs.extend,
      bg: namedArgs.bg
    };
    var queryStr = Object.keys(imageServerConfig).reduce(function (str, config) {
      var setting = imageServerConfig[config];

      if (setting) {
        str += "".concat(config, "=").concat(setting, "&");
      }

      return str;
    }, '?');
    url += queryStr;
    return url;
  }

  var _default = Ember.Helper.helper(imageUrl);

  _exports.default = _default;
});