define("bito-shop-frontend/components/option-type", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.optionValuesSorted}}
    <div class="options__item">
      <label class="options__label" for="option-{{@optionType.id}}">
        {{{this.presentation}}}
      </label>
      {{#if (gt this.optionValuesSorted.length 1)}}
        <div class="select select--native">
          <select
            onChange={{action "handleOptionValueChange" value="target"}}
            data-test-product="select"
          >
            {{#each this.optionValuesSorted as |optionValue|}}
              <OptionValue
                @optionValue={{optionValue}}
                @activeVariant={{@activeVariant}}
                @optionTypeId={{this.optionTypeId}}
                @product={{@product}}
                @setDisabledOptionValues={{this.setDisabledOptionValues}}
                @variants={{variants}}
              />
            {{/each}}
          </select>
        </div>
      {{else}}
        <p>{{this.optionValuesSorted.firstObject.presentation}}</p>
      {{/if}}
    </div>
  {{/if}}
  
  
  */
  {
    "id": "qwPrIvn1",
    "block": "{\"symbols\":[\"optionValue\",\"@activeVariant\",\"@product\",\"@optionType\"],\"statements\":[[6,[37,5],[[32,0,[\"optionValuesSorted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"options__item\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"options__label\"],[15,\"for\",[31,[\"option-\",[32,4,[\"id\"]]]]],[12],[2,\"\\n      \"],[2,[32,0,[\"presentation\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,0,[\"optionValuesSorted\",\"length\"]],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"select select--native\"],[12],[2,\"\\n        \"],[10,\"select\"],[15,\"onChange\",[30,[36,1],[[32,0],\"handleOptionValueChange\"],[[\"value\"],[\"target\"]]]],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"optionValuesSorted\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"option-value\",[],[[\"@optionValue\",\"@activeVariant\",\"@optionTypeId\",\"@product\",\"@setDisabledOptionValues\",\"@variants\"],[[32,1],[32,2],[32,0,[\"optionTypeId\"]],[32,3],[32,0,[\"setDisabledOptionValues\"]],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[32,0,[\"optionValuesSorted\",\"firstObject\",\"presentation\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"variants\",\"action\",\"-track-array\",\"each\",\"gt\",\"if\"]}",
    "moduleName": "bito-shop-frontend/components/option-type.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    product: null,
    optionType: null,
    activeVariant: null,
    setDisabledOptionValues: Function.prototype,
    // passed through to option-values
    optionTypeId: Ember.computed.readOnly('optionType.id'),
    presentation: Ember.computed.readOnly('optionType.presentation'),
    optionValues: Ember.computed.readOnly('optionType.option_values'),
    optionValuesSorted: Ember.computed('optionValues.[]', function () {
      var values = this.get('optionValues');
      var sortedValues = values;

      if (values.length <= 1) {// noop
      } else if (!isNaN(values[0].presentation)) {
        sortedValues = values.sort(function (a, b) {
          return +a.presentation - +b.presentation;
        });
      } else if (!isNaN(values[0].presentation.split(' ')[0])) {
        sortedValues = values.sort(function (a, b) {
          return +a.presentation.split(' ')[0] - +b.presentation.split(' ')[0];
        });
      }

      return sortedValues;
    }),
    actions: {
      handleOptionValueChange: function handleOptionValueChange(selectElement) {
        var _this = this;

        var activeOptionValues = this.get('activeVariant.optionValues');
        var optionTypeId = this.get('optionTypeId');
        var optionValueId = +selectElement.value;
        var productOptions = this.get('product.options');
        var optionsWillChange = (0, _jquery.default)(selectElement).find('option:selected').hasClass('is-disabled');
        var newVariant = this.mystique.fetchVariantByOptions({
          productOptions: productOptions,
          activeOptionValues: activeOptionValues,
          optionTypeId: optionTypeId,
          optionValueId: optionValueId
        });
        return newVariant.then(function () {
          _this.get('switchToVariant')(newVariant);

          if (optionsWillChange) {
            _this.mystique.trigger('variantOptionsDidChange');
          }
        });
      }
    }
  }));

  _exports.default = _default;
});