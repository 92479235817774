define("bito-shop-frontend/mixins/validation-helpers", ["exports", "bito-shop-frontend/config/environment", "ember-cp-validations"], function (_exports, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildAddressValidations = _exports.buildSignupFormValidations = _exports.buildNoNumbersValidator = _exports.buildPresenceValidator = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var buildPresenceValidator = function buildPresenceValidator() {
    return (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    });
  };

  _exports.buildPresenceValidator = buildPresenceValidator;

  var buildNoNumbersValidator = function buildNoNumbersValidator() {
    return (0, _emberCpValidations.validator)('format', {
      regex: /^\D+$/,
      allowBlank: true
    });
  };

  _exports.buildNoNumbersValidator = buildNoNumbersValidator;

  var buildSignupFormValidations = function buildSignupFormValidations() {
    return {
      'newUser.email': {
        debounce: 250,
        disabled: Ember.computed.not('model.validateSignupForm'),
        validators: [buildPresenceValidator()]
      },
      'newUser.password': {
        debounce: 250,
        disabled: Ember.computed.not('model.validateSignupForm'),
        validators: [buildPresenceValidator(), (0, _emberCpValidations.validator)('length', {
          min: 6,
          allowBlank: true
        })]
      },
      'newUser.passwordConfirmation': {
        debounce: 250,
        disabled: Ember.computed.not('model.validateSignupForm'),
        validators: [buildPresenceValidator(), (0, _emberCpValidations.validator)('inline', {
          dependentKeys: ['model.newUser.password'],
          validate: function validate(value
          /*, options, model, attribute*/
          ) {
            if (!value) return true;
            return value === this.get('model.newUser.password') || this.createErrorMessage('password');
          }
        })]
      }
    };
  };

  _exports.buildSignupFormValidations = buildSignupFormValidations;

  var buildAddressValidations = function buildAddressValidations(property) {
    var _ref;

    return _ref = {}, _defineProperty(_ref, "".concat(property, ".title"), {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed(function () {
          return !_environment.default.tenant.validateTitle;
        })
      })]
    }), _defineProperty(_ref, "".concat(property, ".firstname"), {
      validators: [buildPresenceValidator()]
    }), _defineProperty(_ref, "".concat(property, ".lastname"), {
      validators: [buildPresenceValidator()]
    }), _defineProperty(_ref, "".concat(property, ".company"), {
      validators: _environment.default.tenant.googleCheckoutCompliant ? [] : [buildPresenceValidator()]
    }), _defineProperty(_ref, "".concat(property, ".address1"), {
      validators: [buildPresenceValidator()]
    }), _defineProperty(_ref, "".concat(property, ".zipcode"), {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed(function () {
          return !_environment.default.tenant.validateZipcode;
        })
      })]
    }), _defineProperty(_ref, "".concat(property, ".city"), {
      validators: [buildPresenceValidator()]
    }), _defineProperty(_ref, "".concat(property, ".country"), {
      validators: [buildPresenceValidator()]
    }), _defineProperty(_ref, "".concat(property, ".phone"), {
      validators: [buildPresenceValidator()]
    }), _defineProperty(_ref, "".concat(property, ".vatId"), {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.mystique.validateVatID', function () {
          return property !== 'billAddress' || !this.model.mystique.get('validateVatID');
        })
      })]
    }), _ref;
  };

  _exports.buildAddressValidations = buildAddressValidations;
});