define("bito-shop-frontend/templates/checkout/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Llms/G4",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"checkout-address\",[],[[\"@countries\",\"@order\",\"@billAddress\",\"@shipAddress\",\"@transitionCheckoutState\"],[[32,1,[\"countries\"]],[32,0,[\"mystique\",\"currentOrder\"]],[32,0,[\"mystique\",\"currentOrder\",\"billAddress\"]],[32,0,[\"mystique\",\"currentOrder\",\"shipAddress\"]],[30,[36,0],[\"transitionCheckoutState\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "moduleName": "bito-shop-frontend/templates/checkout/address.hbs"
  });

  _exports.default = _default;
});