define("bito-shop-frontend/mixins/scroll-operator", ["exports", "ember-scroll-operator/mixins/scroll-operator", "bito-shop-frontend/lib/parse-url"], function (_exports, _scrollOperator, _parseUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_scrollOperator.default, {
    mystique: Ember.inject.service(),
    beforeModel: function beforeModel() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var transition = args[0];

      this._super.apply(this, args);

      var forceScrollPos = this.get('forceScrollPos');
      var location = this.mystique.isFastBoot ? undefined : document.location;

      if (location !== null && location !== void 0 && location.href && !(0, _parseUrl.default)(location.href).hash) {
        if (this.get('forceScrollPos') > -1) {
          this.controller.set('currentPosition', forceScrollPos);
        } else if (!this._didTransitionViaBackOrForward(transition) && this.controller) {
          this.controller.set('currentPosition', 0);
        }
      }

      this._detachEvents();
    },
    _didTransitionViaBackOrForward: function _didTransitionViaBackOrForward(transition) {
      return transition && transition.sequence > 1 && !transition.urlMethod;
    }
  });

  _exports.default = _default;
});