define("bito-shop-frontend/components/line-item-bundle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.noTable}}
    <div class="config-cart {{if isActive "is-active" ""}}">
      <div class={{if this.modalView "config-modal__inside" "config-cart__inside"}}>
        <div class="config-items">
          {{#each bundledItems as |bundleVariant| }}
            <BundleItem @model={{bundleVariant}}
                        @showDescription={{this.showDescription}}
                        @linkable={{@linkable}} />
          {{/each}}
        </div>
      </div>
    </div>
  {{else}}
    <tr class="config-cart {{if isActive "is-active" ""}}">
      <td colspan="5">
        <div class={{if this.modalView "config-modal__inside" "config-cart__inside"}}>
          <div class="config-items">
            {{#each bundledItems as |bundleVariant| }}
              <BundleItem @model={{bundleVariant}}
                          @showDescription={{this.showDescription}}
                          @linkable={{@linkable}} />
            {{/each}}
          </div>
        </div>
      </td>
    </tr>
  {{/if}}
  
  
  */
  {
    "id": "/Cyt3FoK",
    "block": "{\"symbols\":[\"bundleVariant\",\"bundleVariant\",\"@linkable\"],\"statements\":[[6,[37,1],[[32,0,[\"noTable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"config-cart \",[30,[36,1],[[35,0],\"is-active\",\"\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,1],[[32,0,[\"modalView\"]],\"config-modal__inside\",\"config-cart__inside\"],null]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"config-items\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"bundle-item\",[],[[\"@model\",\"@showDescription\",\"@linkable\"],[[32,2],[32,0,[\"showDescription\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"tr\"],[15,0,[31,[\"config-cart \",[30,[36,1],[[35,0],\"is-active\",\"\"],null]]]],[12],[2,\"\\n    \"],[10,\"td\"],[14,\"colspan\",\"5\"],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[30,[36,1],[[32,0,[\"modalView\"]],\"config-modal__inside\",\"config-cart__inside\"],null]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"config-items\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"bundle-item\",[],[[\"@model\",\"@showDescription\",\"@linkable\"],[[32,1],[32,0,[\"showDescription\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isActive\",\"if\",\"bundledItems\",\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/components/line-item-bundle.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    model: null,
    isActive: false,
    showDescription: true,
    bundledItems: Ember.computed('model', 'mystique.currentOrder.lineItems.[]', function () {
      var variantId = this.get('model.variantId');
      var order = this.get('mystique.currentOrder');
      return order.get('lineItems').filter(function (line) {
        return line.get('parentBundleId') == variantId;
      });
    })
  }));

  _exports.default = _default;
});