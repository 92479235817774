define("bito-shop-frontend/mixins/slider-with-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      var elemId = this.get('elementId');
      this.sliderConfig = Ember.assign({}, this.sliderConfig, {
        nextButton: "#".concat(elemId, " .js-slider-next"),
        prevButton: "#".concat(elemId, " .js-slider-prev")
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});