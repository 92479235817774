define("bito-shop-frontend/routes/error", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    titleToken: function titleToken(model) {
      return "Fehler ".concat(model.status);
    },
    model: function model()
    /*params*/
    {
      return Ember.RSVP.hash({
        status: '404',
        contactInformation: this.mystique.tenantConfig.contactInformation.filter(function (c) {
          return c.error;
        })
      });
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', {
        title: model.status
      });
    },
    activate: function activate() {
      this._super.apply(this, arguments); // ensure loadingOverlay is hidden on canceled transitions


      Ember.run.schedule('afterRender', this, function () {
        this.set('mystique.viewState.loadingOverlay', false);
      }); // Default to 404 status for error page

      if (this.get('fastboot.isFastBoot') && this.get('fastboot.response.statusCode') === 200) {
        this.set('fastboot.response.statusCode', 404);
      }
    }
  });

  _exports.default = _default;
});