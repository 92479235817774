define("bito-shop-frontend/components/proceed-to-checkout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    disabled={{mystique.checkouts.isLoading}}
    class="btn {{addClass}}"
    data-test-cart="checkout"
    {{action "transitionToCheckout"}}
  >
    <span>{{t "cart.proccedToCheckout"}}</span>
  </button>
  
  */
  {
    "id": "fXcnPQ2j",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,\"disabled\",[34,0,[\"checkouts\",\"isLoading\"]]],[16,0,[31,[\"btn \",[34,1]]]],[4,[38,2],[[32,0],\"transitionToCheckout\"],null],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[30,[36,3],[\"cart.proccedToCheckout\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mystique\",\"addClass\",\"action\",\"t\"]}",
    "moduleName": "bito-shop-frontend/components/proceed-to-checkout.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    actions: {
      transitionToCheckout: function transitionToCheckout() {
        var ApplicationController = Ember.getOwner(this).lookup('route:application');
        this.mystique.get('checkouts').transition('address').finally(function () {
          ApplicationController.transitionTo('checkout');
        });
      }
    }
  }));

  _exports.default = _default;
});