define("bito-shop-frontend/mixins/checkout-type-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    checkoutType: '',
    newUser: null,
    // model user
    guestCheckout: 'guest',
    registerCheckout: 'register',
    isCheckoutTypeSelected: Ember.computed.bool('checkoutType'),
    needsToPickCheckoutType: Ember.computed('session.isAuthenticated', 'isCheckoutTypeSelected', function () {
      return !this.session.get('isAuthenticated') && !this.get('isCheckoutTypeSelected');
    }),
    isGuestCheckout: Ember.computed('checkoutType', 'session.isAuthenticated', function () {
      return this._assertCheckoutType(this.guestCheckout);
    }),
    isRegisterCheckout: Ember.computed('checkoutType', 'session.isAuthenticated', function () {
      return this._assertCheckoutType(this.registerCheckout);
    }),
    setCheckoutType: function setCheckoutType(type) {
      this.set('checkoutType', type);

      if (type === this.get('registerCheckout')) {
        this.set('newUser', this.get('store').createRecord('user'));
      }
    },
    resetCheckoutType: function resetCheckoutType() {
      this.set('checkoutType', '');
      this.set('newUser', null);
    },
    _assertCheckoutType: function _assertCheckoutType(type) {
      if (this.get('session.isAuthenticated')) {
        return false;
      } else {
        return this.checkoutType === type;
      }
    }
  });

  _exports.default = _default;
});