define("bito-shop-frontend/components/account-orders-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
    <td class="row-order__sku" data-table={{t "account.order"}}>
      {{@order.id}}
    </td>
    <td class="row-order__date" data-table={{t "account.orderDate"}}>
      {{format-date @order.completedAt}}
    </td>
    <td class="row-order__address" data-table={{t "account.shippingAddress"}}>
      {{@order.shipAddress.company}},<br>
      {{@order.shipAddress.zipcode}} {{@order.shipAddress.city}}
    </td>
    <td class="row-order__total" data-table={{t "account.orderTotal"}}>
      <span class="nowrap">
        <XCurrency @currency={{@order.total}} />
      </span>
    </td>
    <td class="row-order__action text-right">
      <LinkTo @route="account.orders.show" @model={{@order.id}} class="link link__more-right">
        {{t "account.showOrder"}}
      </LinkTo>
    </td>
  </tr>
  
  */
  {
    "id": "JT1MIia+",
    "block": "{\"symbols\":[\"@order\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"row-order__sku\"],[15,\"data-table\",[30,[36,0],[\"account.order\"],null]],[12],[2,\"\\n    \"],[1,[32,1,[\"id\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"row-order__date\"],[15,\"data-table\",[30,[36,0],[\"account.orderDate\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"completedAt\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"row-order__address\"],[15,\"data-table\",[30,[36,0],[\"account.shippingAddress\"],null]],[12],[2,\"\\n    \"],[1,[32,1,[\"shipAddress\",\"company\"]]],[2,\",\"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[1,[32,1,[\"shipAddress\",\"zipcode\"]]],[2,\" \"],[1,[32,1,[\"shipAddress\",\"city\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"row-order__total\"],[15,\"data-table\",[30,[36,0],[\"account.orderTotal\"],null]],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"nowrap\"],[12],[2,\"\\n      \"],[8,\"x-currency\",[],[[\"@currency\"],[[32,1,[\"total\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"row-order__action text-right\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"link link__more-right\"]],[[\"@route\",\"@model\"],[\"account.orders.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"account.showOrder\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"format-date\"]}",
    "moduleName": "bito-shop-frontend/components/account-orders-row.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});