define("bito-shop-frontend/components/controlled-slider-thumbs", ["exports", "bito-shop-frontend/components/base-slider", "bito-shop-frontend/mixins/slider-with-arrows"], function (_exports, _baseSlider, _sliderWithArrows) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield collection}}
  
  <SliderArrows @sliderIsActive={{sliderIsActive}}/>
  
  */
  {
    "id": "awSmTQYY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,0]]],[2,\"\\n\\n\"],[8,\"slider-arrows\",[],[[\"@sliderIsActive\"],[[34,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"collection\",\"sliderIsActive\"]}",
    "moduleName": "bito-shop-frontend/components/controlled-slider-thumbs.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _baseSlider.default.extend(_sliderWithArrows.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.sliderConfig = {
        observer: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        breakpoints: {
          1023: {
            slidesPerView: 4
          }
        }
      };
    },
    sliderSelector: Ember.computed(function () {
      return "#".concat(this.get('elementId'), " .swiper-container");
    }),
    slidesToScroll: 1,
    slidesToShow: 8,
    sliderInstance: null,
    // swiper slider
    setSliderInstance: function setSliderInstance() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('setSliderInstance')(this.get('sliderInstance'));
    }
  }));

  _exports.default = _default;
});