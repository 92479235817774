define("bito-shop-frontend/routes/contact", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.breadCrumb = {
        title: 'Kontakt'
      };
    },
    titleToken: 'Kontakt'
  });

  _exports.default = _default;
});