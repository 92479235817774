define("bito-shop-frontend/templates/components/ember-chimp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CWBr4LFH",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],null,[[\"key-press\",\"class\",\"placeholder\",\"value\",\"type\",\"name\"],[[30,[36,6],[[32,0],\"valueDidChange\"],null],\"newsletter__input required email\",[35,5],[35,4],\"email\",\"EMAIL\"]]]],[2,\"\\n\\n\"],[10,\"button\"],[15,0,[31,[\"btn btn__icon newsletter__btn-small \",[30,[36,2],[[35,8],\"loading\"],null]]]],[14,4,\"submit\"],[12],[2,\"\\n  \"],[8,\"x-icon\",[],[[\"@id\"],[\"paper-plane-regular\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,9],[[35,0],\"idle\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[15,0,[31,[[30,[36,2],[[30,[36,1],[[35,0],\"success\"],null],\"message--success\",\"message--error\"],null],\" message__icon\"]]],[12],[2,\"\\n    \"],[1,[34,3]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"chimpState\",\"eq\",\"if\",\"chimpSays\",\"value\",\"placeholder\",\"action\",\"input\",\"isLoading\",\"not-eq\"]}",
    "moduleName": "bito-shop-frontend/templates/components/ember-chimp.hbs"
  });

  _exports.default = _default;
});