define("bito-shop-frontend/components/summary-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p>
    {{address.title}}<br>
    {{address.firstname}} {{address.lastname}}<br>
    {{#if address.company}}
      {{address.company}}<br>
    {{/if}}
    {{address.address1}}<br>
    {{#if address.address2}}
      {{address.address2}}<br>
    {{/if}}
    {{address.zipcode}} {{address.city}}<br>
    {{address.country.name}}<br>
    {{email}}
  </p>
  
  
  */
  {
    "id": "QxF89qm1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[2,\"\\n  \"],[1,[35,0,[\"title\"]]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[35,0,[\"firstname\"]]],[2,\" \"],[1,[35,0,[\"lastname\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[35,0,[\"company\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[35,0,[\"company\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[35,0,[\"address1\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[35,0,[\"address2\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[35,0,[\"address2\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[35,0,[\"zipcode\"]]],[2,\" \"],[1,[35,0,[\"city\"]]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[35,0,[\"country\",\"name\"]]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[34,2]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"address\",\"if\",\"email\"]}",
    "moduleName": "bito-shop-frontend/components/summary-address.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});