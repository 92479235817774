define("bito-shop-frontend/components/text-slider", ["exports", "bito-shop-frontend/components/base-slider", "bito-shop-frontend/mixins/slider-with-arrows"], function (_exports, _baseSlider, _sliderWithArrows) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if collection}}
    <div class="grid grid--max">
      <div class="grid__col-">
        <div class="textslider__slider swiper-container">
          <div class="swiper-wrapper">
            {{#each collection as |textSlider|}}
              <div class="swiper-slide">
                <p>
                  {{#cms-link href=textSlider.targetPage
                              newTab=textSlider.newTab }}
                    {{textSlider.text}}<strong>{{textSlider.linktext}}</strong>
                  {{/cms-link}}
                </p>
              </div>
            {{/each}}
          </div>
        </div>
  
        <SliderArrows @sliderIsActive={{sliderIsActive}}/>
  
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "2wEfVOtH",
    "block": "{\"symbols\":[\"textSlider\"],\"statements\":[[6,[37,5],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"grid grid--max\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid__col-\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"textslider__slider swiper-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"swiper-wrapper\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"swiper-slide\"],[12],[2,\"\\n              \"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"href\",\"newTab\"],[[32,1,[\"targetPage\"]],[32,1,[\"newTab\"]]]],[[\"default\"],[{\"statements\":[[2,\"                  \"],[1,[32,1,[\"text\"]]],[10,\"strong\"],[12],[1,[32,1,[\"linktext\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"slider-arrows\",[],[[\"@sliderIsActive\"],[[34,4]]],null],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"cms-link\",\"collection\",\"-track-array\",\"each\",\"sliderIsActive\",\"if\"]}",
    "moduleName": "bito-shop-frontend/components/text-slider.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _baseSlider.default.extend(_sliderWithArrows.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.sliderConfig = {
        autoplay: 3000,
        delay: 2000,
        speed: 1000,
        loop: true
      };
    },
    classNames: ['textslider', 'panel'],
    sliderSelector: Ember.computed(function () {
      return '#' + this.get('elementId') + ' .swiper-container';
    }),
    slidesToShow: 1,
    slidesToScroll: 1
  }));

  _exports.default = _default;
});