define("bito-shop-frontend/routes/cart", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    gtm: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('cart.label')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.get('intl').t('cart.label');
    }),
    beforeModel: function beforeModel() {
      var currentOrder = this.mystique.get('currentOrder');

      if (currentOrder) {
        this.trackGA4();
      } else {
        this.mystique.one('currentOrderSet', function () {
          this.trackGA4();
        });
      }
    },
    trackGA4: function trackGA4() {
      var currentOrder = this.mystique.get('currentOrder'); // ga4 events

      this.gtm.trackEvent({
        ecommerce: null
      });
      this.gtm.trackEvent({
        event: 'view_cart',
        ecommerce: {
          value: currentOrder.get('total'),
          currency: currentOrder.get('currency'),
          coupon: currentOrder.get('promotionCodes') || '',
          items: [currentOrder.get('lineItems').map(function (lineItem) {
            return {
              item_id: lineItem.get('sku') || lineItem.get('variant.stepId'),
              item_name: lineItem.get('name'),
              item_variant: lineItem.get('variant.executionDescription'),
              quantity: lineItem.get('quantity')
            };
          })]
        }
      });
    },
    resetController: function resetController(_, isExiting
    /*, transition*/
    ) {
      this._super.apply(this, arguments);

      if (isExiting && this.mystique.get('currentOrder')) {
        this.mystique.get('currentOrder.lineItems').invoke('rollbackAttributes');
      }
    }
  });

  _exports.default = _default;
});