define("bito-shop-frontend/components/account-orders-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="table table-order table-simple">
    <colgroup>
      <col id="row-order-sku">
      <col id="row-order-date">
      <col id="row-order-address">
      <col id="row-order-total">
      <col id="row-order-action">
    </colgroup>
    <thead>
      <tr>
        <th class="row-order__sku">{{t "account.order"}}</th>
        <th class="row-order__date">{{t "account.orderDate"}}</th>
        <th class="row-order__address">{{t "account.shippingAddress"}}</th>
        <th class="row-order__total">{{t "account.orderTotal"}}</th>
        <th class="row-order__action"></th>
      </tr>
    </thead>
    <tbody>
      {{#each @orders as |order|}}
        <AccountOrdersRow @order={{order}} />
      {{/each}}
    </tbody>
  </table>
  
  */
  {
    "id": "dGUrJXU8",
    "block": "{\"symbols\":[\"order\",\"@orders\"],\"statements\":[[10,\"table\"],[14,0,\"table table-order table-simple\"],[12],[2,\"\\n  \"],[10,\"colgroup\"],[12],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-order-sku\"],[12],[13],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-order-date\"],[12],[13],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-order-address\"],[12],[13],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-order-total\"],[12],[13],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-order-action\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[14,0,\"row-order__sku\"],[12],[1,[30,[36,0],[\"account.order\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"row-order__date\"],[12],[1,[30,[36,0],[\"account.orderDate\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"row-order__address\"],[12],[1,[30,[36,0],[\"account.shippingAddress\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"row-order__total\"],[12],[1,[30,[36,0],[\"account.orderTotal\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"row-order__action\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"account-orders-row\",[],[[\"@order\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/components/account-orders-table.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});