define("bito-shop-frontend/routes/checkout/address", ["exports", "ember-scroll-operator/mixins/scroll-operator", "bito-shop-frontend/routes/checkout-step"], function (_exports, _scrollOperator, _checkoutStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _checkoutStep.default.extend(_scrollOperator.default, {
    model: function model() {
      var isos = this.mystique.get('tenantConfig.countries') || [];
      return Ember.RSVP.hash({
        countries: this.store.query('country', {
          isos: isos
        })
      });
    },
    afterModel: function afterModel(model) {
      // set default country if no country is set
      var defaultCountryISO = this.mystique.get('tenantConfig.defaultCountry');

      if (defaultCountryISO) {
        this.mystique.get('currentOrder.billAddress').then(function (ba) {
          if (!ba.belongsTo('country').id()) {
            var defaultCountry = model.countries.findBy('iso', defaultCountryISO);
            ba.set('country', defaultCountry);
          }
        });
      }
    },
    actions: {
      returnToCart: function returnToCart() {
        this.transitionTo('cart');
      }
    }
  });

  _exports.default = _default;
});