define("bito-shop-frontend/components/option-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <option class={{if disabled "is-disabled"}}
          value={{optionValue.id}}
          selected={{selected}}
          data-test-product="dimensions">
    {{presentation}}{{if disabled " | *"}}
  </option>
  
  
  */
  {
    "id": "1VPQ4SHd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"option\"],[15,0,[30,[36,1],[[35,0],\"is-disabled\"],null]],[15,2,[34,2,[\"id\"]]],[15,\"selected\",[34,3]],[12],[2,\"\\n  \"],[1,[34,4]],[1,[30,[36,1],[[35,0],\" | *\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\",\"optionValue\",\"selected\",\"presentation\"]}",
    "moduleName": "bito-shop-frontend/components/option-value.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    product: null,
    activeVariant: null,
    optionValue: null,
    optionTypeId: null,
    presentation: Ember.computed.reads('optionValue.presentation'),
    selected: Ember.computed('activeVariant', 'optionValue', function () {
      return this.get('activeVariant.optionValueIds').indexOf(this.get('optionValue.id')) >= 0;
    }),
    disabled: Ember.computed('activeVariant', function () {
      var _this = this;

      var optionTypeId = this.get('optionTypeId');
      var productOptionTypes = this.get('product.options');
      if (productOptionTypes.length < 2) return false;
      var targetOptionValues = this.get('activeVariant.optionValues').reduce(function (ary, ov) {
        var currentOptionTypeId = +ov.belongsTo('optionType').id();
        var currentOptionTypeIsValidForProduct = productOptionTypes.map(function (t) {
          return t.id;
        }).includes(currentOptionTypeId);

        var currentOptionTypeIsNotEqToComponentOption = currentOptionTypeId !== _this.get('optionTypeId');

        if (currentOptionTypeIsValidForProduct && currentOptionTypeIsNotEqToComponentOption) ary.push([+ov.get('id'), currentOptionTypeId]);
        return ary;
      }, []);
      targetOptionValues.push([this.get('optionValue.id'), optionTypeId]);
      var targetOptionValueVariantIds = targetOptionValues.map(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
            optionValueId = _ref3[0],
            optionTypeId = _ref3[1];

        return productOptionTypes.find(function (ot) {
          return ot.id === optionTypeId;
        }).option_values.find(function (ov) {
          return ov.id === optionValueId;
        }).variant_ids;
      });
      var disabled = !targetOptionValueVariantIds.reduce(function (res, ids) {
        return res.filter(function (id) {
          return ids.includes(id);
        });
      }).length; // side-effect! (improve this)

      if (disabled) this.setDisabledOptionValues(true);
      return disabled;
    })
  }));

  _exports.default = _default;
});