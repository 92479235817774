define("bito-shop-frontend/mixins/slider-with-fade-loop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    speed: 400,
    loop: true,
    effect: 'fade',
    didInsertElement: function didInsertElement() {
      this.sliderConfig = Ember.assign({}, this.sliderConfig, {
        speed: this.get('speed'),
        loop: this.get('loop'),
        effect: this.get('effect')
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});