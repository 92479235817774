define("bito-shop-frontend/helpers/src-set", ["exports", "bito-shop-frontend/config/environment", "bito-shop-frontend/helpers/cdn-src"], function (_exports, _environment, _cdnSrc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.srcSet = srcSet;
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // IMAGE SERVER CONFIG
  //
  // w: width in px
  // h: height in px
  // chromasub: 444, server defaults to 420
  // fm: 'pjpeg', 'png', 'jpeg', 'jpeg.svg', 'webp', 'png8'
  // q: quality 0...100 (default: 80)
  // fit: 'clip' (default), 'max', 'crop', 'fill', 'fillmax', 'clamp'
  // crop: (default: crop from center), 'left,top', 'bottom', 'bottom,right' etc.
  // extend: (default: extend from center), 'left,top', 'bottom', 'bottom,right' etc.
  // bg: background fill rgb/argb hex, default: transparent or white), 'f00' (red), 'fafafa', 'cf00' (50% red), 'cc0000ff' (50% blue)
  // fn: set filename for image (extension is set from fm param or kept)
  // dl: set filename for downloadable image (extension is set from fm param or kept)
  var JPG = 'jpg';
  var SVG = 'svg';
  var WEBP = 'webp';
  var PNG = 'png';
  var formatExtensions = {
    png: PNG,
    png8: PNG,
    png32: PNG,
    pjpeg: JPG,
    pjpg: JPG,
    jpeg: JPG,
    jpg: JPG,
    'jpeg.svg': SVG,
    webp: WEBP
  };

  function buildUrl(url, params) {
    var queryStr = Object.keys(params).reduce(function (str, param) {
      if (param === 'fn' || param === 'dl') {
        var origExt = url.split('.').get('lastObject');
        var newExt = params.fm;
        var filename = window.encodeURIComponent(params[param]).replace(/\..+$/, ''); // filename and url can have different extensions

        var newFilename = "".concat(filename, ".").concat(formatExtensions[newExt] || origExt);
        str += "".concat(param, "=").concat(newFilename, "&");
      } else {
        str += "".concat(param, "=").concat(params[param], "&");
      }

      return str;
    }, '?');
    url += queryStr;
    return url;
  }

  function srcSet(_ref, config) {
    var _ref2 = _toArray(_ref),
        url = _ref2[0],
        srcs = _ref2.slice(1);

    var result;

    if (typeof url !== 'string') {
      result = _environment.default.environment === 'development' ? "//dummyimage.com/".concat(config.width || 100, "x").concat(config.height || 100, "/f7f7f7/999998") // dummy image
      : (0, _cdnSrc.cdnSrc)(['global/fallback-product-images.png']);
    } else if (!srcs.length) {
      // simple url to image with image server query string
      result = buildUrl(url, config);
    } else {
      // multiple comma seperated urls and descriptors
      result = srcs.reduce(function (srcset, src) {
        var params = Ember.assign({}, config, src);
        var descr = src.descr || '1x';
        delete params.descr;
        return srcset += "".concat(buildUrl(url, params), " ").concat(descr, ",");
      }, '').replace(/,$/, '');
    }

    return result;
  }

  var _default = Ember.Helper.helper(srcSet);

  _exports.default = _default;
});