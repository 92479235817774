define("bito-shop-frontend/mixins/store-id-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var stores = ['DE', 'UK']; // extract into process env

  var storeHelpers = stores.reduce(function (mem, storeID) {
    mem["is".concat(storeID, "Store")] = Ember.computed.equal('env.tenantID', storeID);
    return mem;
  }, {});

  var _default = Ember.Mixin.create(storeHelpers);

  _exports.default = _default;
});