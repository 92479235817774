define("bito-shop-frontend/components/solutions-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
    <a role="button"
      href={{mystique.tenantConfig.solutionsLink}}
      target="_blank"
      rel="noopener"
      class="header-menu__link header-menu__link--secondary"
      title={{t "base.bitoSolutions"}}>
      <span class="header-menu__btn">
        <XIcon @id="external-link" />
        <span class="header-menu__label">{{t "base.bitoSolutions"}}</span>
      </span>
    </a>
  </div>
  
  */
  {
    "id": "/pCmfC3g",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,\"role\",\"button\"],[15,6,[34,0,[\"tenantConfig\",\"solutionsLink\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[14,0,\"header-menu__link header-menu__link--secondary\"],[15,\"title\",[30,[36,1],[\"base.bitoSolutions\"],null]],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"header-menu__btn\"],[12],[2,\"\\n      \"],[8,\"x-icon\",[],[[\"@id\"],[\"external-link\"]],null],[2,\"\\n      \"],[10,\"span\"],[14,0,\"header-menu__label\"],[12],[1,[30,[36,1],[\"base.bitoSolutions\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mystique\",\"t\"]}",
    "moduleName": "bito-shop-frontend/components/solutions-navigation.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});