define("bito-shop-frontend/helpers/priceFormatAsNumber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.priceFormatAsNumber = priceFormatAsNumber;
  _exports.default = void 0;

  function priceFormatAsNumber(price) {
    return parseFloat(price).toFixed(2);
  }

  var _default = Ember.Helper.helper(priceFormatAsNumber);

  _exports.default = _default;
});