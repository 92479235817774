define("bito-shop-frontend/components/press-and-hold-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function sendPress() {
    if (this.get('enabled')) this.get('press')();
  }

  function sendRelease() {
    if (this.get('enabled')) this.get('release')();
  }

  function prevent(e) {
    e.preventDefault();
  }

  var _default = Ember.Component.extend({
    tagName: 'button',
    attributeBindings: ['type'],
    type: 'button',
    press: Function.prototype,
    release: Function.prototype,
    enabled: true,
    click: prevent,
    mouseDown: sendPress,
    mouseUp: sendRelease,
    init: function init() {
      this._super.apply(this, arguments);

      this.sendRelease = sendRelease.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this.element.addEventListener('mouseleave', this.sendRelease);
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('mouseleave', this.sendRelease);
    }
  });

  _exports.default = _default;
});