define("bito-shop-frontend/templates/components/relation-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bomrP/k6",
    "block": "{\"symbols\":[\"@idAttr\",\"@model\",\"@type\"],\"statements\":[[11,\"input\"],[16,\"checked\",[32,0,[\"isSelected\"]]],[16,1,[30,[36,0],[[32,2,[\"idAttr\"]],[32,2,[\"idAttr\"]],[32,1]],null]],[16,4,[32,3]],[4,[38,3],[\"change\",[30,[36,2],[[30,[36,1],[[32,0],\"onChange\"],null]],null]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"fn\",\"on\"]}",
    "moduleName": "bito-shop-frontend/templates/components/relation-select.hbs"
  });

  _exports.default = _default;
});