define("bito-shop-frontend/routes/account/orders/show", ["exports", "bito-shop-frontend/routes/account"], function (_exports, _account) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _account.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.breadCrumb = this.breadCrumb || {
        title: 'Details'
      };
    },
    model: function model(params) {
      this.set('breadCrumb.title', params.order_id);
      return this.store.peekRecord('order', params.order_id);
    }
  });

  _exports.default = _default;
});