define("bito-shop-frontend/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u3Hhwrdc",
    "block": "{\"symbols\":[\"permalink\"],\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"noIndex\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"meta\"],[14,3,\"robots\"],[14,\"content\",\"noindex\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"title\"],[12],[1,[32,0,[\"model\",\"title\"]]],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"model\",\"jsonld\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"script\"],[14,4,\"application/ld+json\"],[12],[2,\"\\n    \"],[1,[32,0,[\"model\",\"jsonld\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"canonical\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"link\"],[14,\"rel\",\"canonical\"],[15,6,[32,0,[\"model\",\"canonical\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"metaDescription\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"meta\"],[14,3,\"description\"],[15,\"content\",[32,0,[\"model\",\"metaDescription\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"model\",\"hreflangLinks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"link\"],[14,\"rel\",\"alternate\"],[15,6,[32,1,[\"link\"]]],[15,\"hreflang\",[32,1,[\"locale\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"meta\"],[14,3,\"google-site-verification\"],[14,\"content\",\"5V__Wzot0cb7jxE_VoSnZkwDj2yRY3CodTt9Z7i56Ag\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/templates/head.hbs"
  });

  _exports.default = _default;
});