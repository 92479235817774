define("bito-shop-frontend/controllers/configurator-taxons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    gtm: Ember.inject.service(),
    showInfoBox: true,
    actions: {
      toggleInfoBox: function toggleInfoBox(name) {
        this.set('showInfoBox', !this.showInfoBox);

        if (name === 'configurator-infobox-btn') {
          this.gtm.trackEvent({
            event: 'EventTracking',
            EventCategory: 'Konfigurator',
            EventAction: 'Übersichtsseite',
            EventLabel: 'Ok, verstanden (Button)'
          });
        }
      }
    }
  });

  _exports.default = _default;
});