define("bito-shop-frontend/mixins/can-open-content-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    contentModal: Ember.inject.service(),
    actions: {
      openModal: function openModal(model, modalType) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        this.contentModal.openModal(model, modalType, options);
      }
    }
  });

  _exports.default = _default;
});