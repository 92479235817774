define("bito-shop-frontend/routes/checkout-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // allow browser history navigation between these states
  var STATE_WHITELIST = ['address', 'delivery', 'payment', 'confirm'];

  var _default = Ember.Route.extend({
    _syncCheckoutStateAndURL: function _syncCheckoutStateAndURL() {
      var currentState = this.mystique.get('checkouts.currentState');
      var targetStep = this.get('routeName').split('.').get('lastObject');

      if (currentState === targetStep || !STATE_WHITELIST.includes(currentState)) {
        return;
      }

      return this.mystique.get('checkouts').transition(targetStep);
    },
    redirect: function redirect()
    /*model, transition*/
    {
      if (this.mystique.get('currentOrder.state') === 'complete') {
        // redirect user to taxons on browser back after complete
        this.transitionTo('taxons', this.get('mystique.tenantConfig.rootTaxon'));
      } else {
        this._super.apply(this, arguments);
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this._syncCheckoutStateAndURL();
    }
  });

  _exports.default = _default;
});