define("bito-shop-frontend/mixins/sends-order-headers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    mystique: Ember.inject.service(),

    /**
      A computed property for the adapter headers.  We use these headers to authenticate
      the user against the order we're trying to modify.  The `spree_ams` gem will
      look for these, and do the work here.
       @property headers
      @type String
      @readOnly
      @default {}
    */
    headers: Ember.computed('mystique.{guestToken,orderId,wishlistId}', function () {
      var guestToken = this.get('mystique.guestToken');
      var orderId = this.get('mystique.orderId');
      var wishlistId = this.get('mystique.wishlistId');
      var headers = {};

      if (guestToken) {
        if (orderId) {
          headers['X-Spree-Order-Token'] = guestToken;
          headers['X-Spree-Order-Id'] = orderId;
        }

        if (wishlistId) {
          headers['X-Spree-Wishlist-Token'] = guestToken;
          headers['X-Spree-Wishlist-Id'] = wishlistId;
        }
      }

      return headers;
    })
  });

  _exports.default = _default;
});