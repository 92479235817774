define("bito-shop-frontend/components/shipping-methods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @model as |shipment|}}
    {{#each shipment.shippingRates as |shippingRate|}}
      <div
        class="pick pick--frame"
        data-test-checkout="shipping-method"
      >
  
        <RelationSelect @model={{shippingRate}}
                        @type="radio"
                        @relation={{shipment.selectedShippingRate}} />
  
        <label for={{shippingRate.idAttr}}>
          <strong class="pick__content">
            {{shippingRate.shippingMethod.name}}
          </strong>
  
          <span class="pick__infos">
            {{shippingRate.shippingMethod.description}}
          </span>
        </label>
      </div>
    {{/each}}
  {{/each}}
  
  */
  {
    "id": "u0fOOFQH",
    "block": "{\"symbols\":[\"shipment\",\"shippingRate\",\"@model\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1,[\"shippingRates\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"pick pick--frame\"],[12],[2,\"\\n\\n      \"],[8,\"relation-select\",[],[[\"@model\",\"@type\",\"@relation\"],[[32,2],\"radio\",[32,1,[\"selectedShippingRate\"]]]],null],[2,\"\\n\\n      \"],[10,\"label\"],[15,\"for\",[32,2,[\"idAttr\"]]],[12],[2,\"\\n        \"],[10,\"strong\"],[14,0,\"pick__content\"],[12],[2,\"\\n          \"],[1,[32,2,[\"shippingMethod\",\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"pick__infos\"],[12],[2,\"\\n          \"],[1,[32,2,[\"shippingMethod\",\"description\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/components/shipping-methods.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});