define("bito-shop-frontend/routes/base-route", ["exports", "bito-shop-frontend/mixins/scroll-operator", "bito-shop-frontend/mixins/can-update-view-state"], function (_exports, _scrollOperator, _canUpdateViewState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * ScrollOperatorMixin
   *
   * If any of the following methods are implemented in your route, be sure to
   * include a call to the parent to make sure the mixin is included.
   *
   * - activate
   * - deactivate
   * - beforeModel
   * - setupController
   *
   */
  var _default = Ember.Route.extend(_scrollOperator.default, _canUpdateViewState.default, {
    intl: Ember.inject.service(),
    headData: Ember.inject.service(),
    layout: 'application',
    renderTemplate: function renderTemplate() {
      this.send('setLayout', this.get('layout'));

      this._super();
    }
  });

  _exports.default = _default;
});