define("bito-shop-frontend/templates/components/wait-for-usercentrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x8eRu7d5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,0],[[32,0,[\"mystique\",\"isFastboot\"]]],null],[30,[36,1],[[30,[36,0],[[32,0,[\"mystique\",\"tenantConfig\",\"prudsysWaitForUsercentrics\"]]],null],[32,0,[\"usercentrics\",\"isLoaded\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"or\",\"and\",\"if\"]}",
    "moduleName": "bito-shop-frontend/templates/components/wait-for-usercentrics.hbs"
  });

  _exports.default = _default;
});