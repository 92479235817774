define("bito-shop-frontend/mixins/storable", ["exports", "bito-shop-frontend/lib/local-storage"], function (_exports, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Storable bolts onto an Ember Object and provides functionality for persisting
    key value pairs to Local Storage.
  
    @class Storable
    @namespace Mixin
    @extends Ember.Mixin
  */
  var _default = Ember.Mixin.create({
    /**
      The Local Storage key we use to Restore and Persist data across browser
      refreshes.
       @property localStorageKey
      @type String
      @readOnly
      @default 'storable'
    */
    localStorageKey: 'storable',

    /**
      Persists an object to Local Storage.  Will overwrite existing values, and
      can be used to nullify an existing value.
       @example
      ```javascript
      this.spree.persist({
        "favoriteColor": "red"
      });
      ```
       @method persist
      @param {Object} data A Javascript Object to persist to Local Storage.
      @return {Boolean} Will always resolve to `true`.
     */
    persist: function persist(data) {
      var key = this.get('localStorageKey');

      this._setOnHost(data); // Get existing data.  Don't set it on the host.


      var existingData = this._fetchLocalStorageData();

      var allData = Ember.assign(existingData, data); // TODO: this bullshit doesn't remove nulled values from localStorage
      // Remove nullified values to avoid "null" strings.

      for (var dataKey in allData) {
        var value = allData[dataKey];

        if (!value) {
          delete allData[dataKey];
        }
      }

      var stringifiedData = JSON.stringify(allData || {});

      _localStorage.default.setItem(key, stringifiedData);

      return true;
    },

    /**
      Sets key value pairs on the Host object from Local Storage.  Usually called
      in intitalizers for Spree Ember addons.
       @example
      ```javascript
      this.spree.restore()
      ```
       @method restore
      @return {Boolean} Will always resolve to `true`.
    */
    restore: function restore() {
      return this._setOnHost(this._fetchLocalStorageData());
    },

    /**
      Sets key value pairs on the Host object.
       @method _setOnHost
      @private
      @param {Object} data A Javascript Object to set on the Ember Object.
      @return {Boolean} Will always resolve to `true`.
    */
    _setOnHost: function _setOnHost(data) {
      for (var key in data) {
        this.set(key, data[key]);
      }

      return true;
    },

    /**
      Returns a Parsed Object from the Local Storage Key.
       @method _fetchLocalStorageData
      @private
      @return {Object} An object of Key Value pairs from local storage.
    */
    _fetchLocalStorageData: function _fetchLocalStorageData() {
      var data, parsed;

      try {
        data = _localStorage.default.getItem(this.get('localStorageKey'), {
          throwError: true
        });
        parsed = JSON.parse(data || '{}');
      } catch (err) {
        this.set('localStorageError', err);
      }

      return parsed;
    }
  });

  _exports.default = _default;
});