define("bito-shop-frontend/components/checkout-edit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p class="summary__btn">
    <a
      role="button"
      class="link link__icon"
      {{on "click" @onClick}}
    >
      <XIcon @id="sync-alt-solid" @class="icon--primary" />
      {{t "base.change"}}
    </a>
  </p>
  
  */
  {
    "id": "7ehM7nG3",
    "block": "{\"symbols\":[\"@onClick\"],\"statements\":[[10,\"p\"],[14,0,\"summary__btn\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,\"role\",\"button\"],[24,0,\"link link__icon\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[8,\"x-icon\",[],[[\"@id\",\"@class\"],[\"sync-alt-solid\",\"icon--primary\"]],null],[2,\"\\n    \"],[1,[30,[36,1],[\"base.change\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"t\"]}",
    "moduleName": "bito-shop-frontend/components/checkout-edit-button.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});