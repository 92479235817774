define("bito-shop-frontend/components/product-modal-slider", ["exports", "bito-shop-frontend/components/base-slider", "bito-shop-frontend/mixins/slider-with-arrows"], function (_exports, _baseSlider, _sliderWithArrows) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="swiper-wrapper u-flex-align-center">
    {{#each @collection as |image idx|}}
      <LazySlide
        @item={{image}}
        @idx={{idx}}
        @slidesToScroll={{this.slidesToScroll}}
        @currentSlide={{sum this.activeSlideIdx 1}}
        @preRenderCount={{0}}
      >
        <picture class="detail__image">
          <img
            src={{src-set image.url fm="pjpeg" q="85" chromasub="444" fit="fill" w="640"}}
            sizes="calc(100vw - 30px)"
            srcset={{src-set image.url
                             (hash w="320" descr="320w")
                             (hash w="480" descr="480w")
                             (hash w="640" descr="640w")
                             (hash w="768" descr="768w")
                             (hash w="1024" descr="1024w")
                             fm="pjpeg"
                             q="85"
                             chromasub="444"
                             fit="fill"}}
            loading="lazy"
            alt={{image.alt_text}}
          >
        </picture>
      </LazySlide>
    {{/each}}
    {{#if @video}}
      <XSovidoInner @class="swiper-no-swiping" @video={{@video}} />
    {{/if}}
  </div>
  
  <SliderArrows @sliderIsActive={{this.sliderIsActive}}/>
  
  */
  {
    "id": "XXXW+LjY",
    "block": "{\"symbols\":[\"image\",\"idx\",\"@video\",\"@collection\"],\"statements\":[[10,\"div\"],[14,0,\"swiper-wrapper u-flex-align-center\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"lazy-slide\",[],[[\"@item\",\"@idx\",\"@slidesToScroll\",\"@currentSlide\",\"@preRenderCount\"],[[32,1],[32,2],[32,0,[\"slidesToScroll\"]],[30,[36,0],[[32,0,[\"activeSlideIdx\"]],1],null],0]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"picture\"],[14,0,\"detail__image\"],[12],[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,[36,1],[[32,1,[\"url\"]]],[[\"fm\",\"q\",\"chromasub\",\"fit\",\"w\"],[\"pjpeg\",\"85\",\"444\",\"fill\",\"640\"]]]],[14,\"sizes\",\"calc(100vw - 30px)\"],[15,\"srcset\",[30,[36,1],[[32,1,[\"url\"]],[30,[36,2],null,[[\"w\",\"descr\"],[\"320\",\"320w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"480\",\"480w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"640\",\"640w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"768\",\"768w\"]]],[30,[36,2],null,[[\"w\",\"descr\"],[\"1024\",\"1024w\"]]]],[[\"fm\",\"q\",\"chromasub\",\"fit\"],[\"pjpeg\",\"85\",\"444\",\"fill\"]]]],[14,\"loading\",\"lazy\"],[15,\"alt\",[32,1,[\"alt_text\"]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[6,[37,5],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"x-sovido-inner\",[],[[\"@class\",\"@video\"],[\"swiper-no-swiping\",[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[8,\"slider-arrows\",[],[[\"@sliderIsActive\"],[[32,0,[\"sliderIsActive\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sum\",\"src-set\",\"hash\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "bito-shop-frontend/components/product-modal-slider.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _baseSlider.default.extend(_sliderWithArrows.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.sliderConfig = {
        observer: true
      };
    },
    classNames: ['swiper-container'],
    variant: null,
    video: null,
    startSlideIndex: 0,
    forceInit: true,
    onActiveChange: Ember.on('init', Ember.observer('isActive', function () {
      var _this = this;

      if (this.get('isActive')) {
        Ember.run.next(this, function () {
          var sliderInstance = _this.get('sliderInstance');

          if (sliderInstance) {
            _this.set('activeSlideIdx', sliderInstance.activeIndex);

            sliderInstance.slideTo(_this.get('startSlideIndex'));
          }
        });
      }
    }))
  }));

  _exports.default = _default;
});