define("bito-shop-frontend/components/nav-main-configurator-teaser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="nav-sub__item">
    <LinkTo
      @route='configurator-taxons'
      @model={{@id}}
      @query={{hash page=1}}
      class="nav-sub__link config-nav"
    >
      <div class="config-nav__image nav-sub__image">
        <XIcon @id="configurate" @class="config-nav__icon" @noClassDefault={{true}} />
      </div>
      <span class="config-nav__label nav-sub__label">
        <span class="u-labeltag">{{t "configurators.new"}}</span>
        {{t "configurators.configurators"}}
      </span>
    </LinkTo>
  </li>
  
  */
  {
    "id": "iQTIsLYM",
    "block": "{\"symbols\":[\"@id\"],\"statements\":[[10,\"li\"],[14,0,\"nav-sub__item\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"nav-sub__link config-nav\"]],[[\"@route\",\"@model\",\"@query\"],[\"configurator-taxons\",[32,1],[30,[36,0],null,[[\"page\"],[1]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"config-nav__image nav-sub__image\"],[12],[2,\"\\n      \"],[8,\"x-icon\",[],[[\"@id\",\"@class\",\"@noClassDefault\"],[\"configurate\",\"config-nav__icon\",true]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"config-nav__label nav-sub__label\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"u-labeltag\"],[12],[1,[30,[36,1],[\"configurators.new\"],null]],[13],[2,\"\\n      \"],[1,[30,[36,1],[\"configurators.configurators\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"t\"]}",
    "moduleName": "bito-shop-frontend/components/nav-main-configurator-teaser.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});