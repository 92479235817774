define("bito-shop-frontend/mixins/current-user-support", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    gtm: Ember.inject.service(),
    currentUser: null,
    setCurrentUser: function setCurrentUser() {
      var _this = this;

      var trackLogin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var userId = this.get('session.session.content.authenticated.id');
      var user = this.store.peekRecord('user', userId);
      var userPromise;

      if (!user) {
        var tempUser = this.get('store').createRecord('user', {
          id: userId
        }); // todo: add explanation! (still have no idea where he needed this...)

        this.set('currentUser', tempUser);
        userPromise = this.store.findRecord('user', userId, {
          reload: true
        });
      } else {
        userPromise = Ember.RSVP.resolve(user);
      }

      return userPromise.then(function (currentUser) {
        _this.set('currentUser', currentUser);

        _this.trigger('userLoggedIn', currentUser);

        if (trackLogin) {
          _this.gtm.trackEvent({
            event: 'login',
            user_id: currentUser.staticId
          });
        }

        Sentry.configureScope(function (scope) {
          scope.setUser({
            id: currentUser.get('id'),
            email: currentUser.get('email')
          });
        });
      });
    },
    loginUser: function loginUser(_ref) {
      var _this2 = this;

      var email = _ref.email,
          password = _ref.password,
          disableTransition = _ref.disableTransition,
          restoreUserData = _ref.restoreUserData;
      disableTransition = disableTransition || false;

      if (typeof restoreUserData === 'undefined') {
        restoreUserData = true;
      }

      this.set('session.disableTransition', disableTransition); // token endpoint needs to lookup order to
      // associate user to guest orders and assign default addresses

      var orderId = this.get('orderId') || '';
      var headers = {
        'X-Spree-Order-Token': this.get('guestToken') || '',
        'X-Spree-Order-Id': orderId,
        'X-Spree-Wishlist-Id': orderId
      };
      var credentials = {
        user: {
          email: email,
          password: password
        }
      };
      return this.get('session').authenticate('authenticator:jwt', credentials, headers).then(function () {
        _this2.setCurrentUser(true);

        if (restoreUserData) {
          _this2._restoreCurrentOrder();

          _this2._restoreCurrentWishlist();
        }
      });
    }
  });

  _exports.default = _default;
});