define("bito-shop-frontend/lib/utils/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadScript = loadScript;
  _exports.default = void 0;

  function loadScript(src) {
    var usercentrics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var script = document.createElement('script');
    script.type = usercentrics ? 'text/plain' : 'application/javascript';
    script.async = true;
    script.src = src;
    if (usercentrics) script.dataset.usercentrics = usercentrics;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  var _default = {
    loadScript: loadScript
  };
  _exports.default = _default;
});