define("bito-shop-frontend/mixins/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    target: Ember.computed(function () {
      return this.get('parentView');
    })
  });

  _exports.default = _default;
});