define("bito-shop-frontend/services/mystique", ["exports", "jquery", "bito-shop-frontend/mixins/storable", "bito-shop-frontend/mixins/current-user-support", "bito-shop-frontend/mixins/current-order-support", "bito-shop-frontend/mixins/current-wishlist-support", "bito-shop-frontend/mixins/checkout-type-support", "bito-shop-frontend/mixins/notifications", "bito-shop-frontend/mixins/store-id-support", "bito-shop-frontend/config/environment", "bito-shop-frontend/constants", "bito-shop-frontend/lib/parse-url"], function (_exports, _jquery, _storable, _currentUserSupport, _currentOrderSupport, _currentWishlistSupport, _checkoutTypeSupport, _notifications, _storeIdSupport, _environment, _constants, _parseUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var GTM_CONFIG = window.gtmConfig;

  var _default = Ember.Service.extend(Ember.Evented, _storable.default, _currentUserSupport.default, _currentOrderSupport.default, _currentWishlistSupport.default, _checkoutTypeSupport.default, _notifications.default, _storeIdSupport.default, {
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.isFastBoot) {
        (0, _jquery.default)(document).on('mouseout', _jquery.default.proxy(this.get('exitEvent'), this));
      }
    },
    exitEvent: function exitEvent(e) {
      if (e.clientY <= 0) {
        (0, _jquery.default)(document).off('mouseout', _jquery.default.proxy(this.get('exitEvent'), this)); // https://louis-internet.atlassian.net/browse/BITOSHP-1470
        // The modal is currently showing a form for registering to the german newsletter so we should only show
        // it for the german region and locale

        if (this.get('currentLocale') === 'de' && this.get('currentRegion') === 'de') this.contentModal.openModal({}, 'exit-intent', {});
      }
    },
    xmasMegaDealIsActive: Ember.computed(function () {
      var date = new Date();
      return date.getDate() < 25 && date.getMonth() + 1 === 12;
    }),
    localePrefix: Ember.computed.reads('env.localePrefix'),
    currentLocale: Ember.computed('localePrefix', function () {
      return this.get('localePrefix').split('-')[0];
    }),
    currentRegion: Ember.computed('localePrefix', function () {
      return this.get('localePrefix').split('-')[1];
    }),
    hasMailchimp: Ember.computed.bool('tenantConfig.mailchimp.listID'),
    hasMegaMailchimp: Ember.computed.bool('tenantConfig.megadealMailchimp.listID'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    adwordsCampaign: {},
    gtmConfig: GTM_CONFIG,
    shoeboxID: _constants.shoeboxID,
    localStorageKey: _constants.localStorageKey,
    currentPathName: Ember.computed.alias('router.url'),
    fetchVariantByOptions: function fetchVariantByOptions(_ref) {
      var _this = this;

      var productOptions = _ref.productOptions,
          activeOptionValues = _ref.activeOptionValues,
          optionTypeId = _ref.optionTypeId,
          optionValueId = _ref.optionValueId;
      var targetOptionValues = activeOptionValues.reduce(function (ary, ov) {
        var currentOptionTypeId = +ov.belongsTo('optionType').id();
        var currentOptionTypeIsValidForProduct = productOptions.mapBy('id').includes(currentOptionTypeId);

        var currentOptionTypeIsNotEqToComponentOption = currentOptionTypeId !== _this.get('optionTypeId');

        if (currentOptionTypeIsValidForProduct && currentOptionTypeIsNotEqToComponentOption) ary.push([+ov.get('id'), currentOptionTypeId]);
        return ary;
      }, []);
      targetOptionValues.unshift([optionValueId, optionTypeId]);
      var targetOptionValueVariantIds = targetOptionValues.map(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
            optionValueId = _ref3[0],
            optionTypeId = _ref3[1];

        return productOptions.find(function (ot) {
          return ot.id === optionTypeId;
        }).option_values.find(function (ov) {
          return ov.id === optionValueId;
        }).variant_ids;
      });
      var newVariantId = targetOptionValueVariantIds.reduce(function (res, ids) {
        var intersection = res.filter(function (id) {
          return ids.includes(id);
        });
        if (intersection.length) return intersection;else return res;
      })[0];
      return this.store.findRecord('variant', newVariantId);
    },
    env: _environment.default,
    tenantConfig: _environment.default.tenant,
    toastGenericError: function toastGenericError() {
      if (!this.get('fastboot').isFastBoot) {
        this.get('toast').error(this.get('intl').t('notifications.genericError'));
      } else {
        console.warn('toastGenericError called in FastBoot context, replace with error logging');
      }
    },
    currentUrl: function currentUrl() {
      return this.baseUrl() + this.path();
    },
    baseUrl: function baseUrl() {
      return this.protocol() + '//' + this.host();
    },
    host: function host() {
      var fastbootRequest = this.get('fastboot.request');

      if (fastbootRequest) {
        return fastbootRequest.get('host');
      } else {
        return window.location.host;
      }
    },
    path: function path() {
      var fastbootRequest = this.get('fastboot.request');

      if (fastbootRequest) {
        return fastbootRequest.get('path');
      } else {
        return window.location.pathname;
      }
    },
    protocol: function protocol() {
      var fastbootRequest = this.get('fastboot.request');
      var protocol = '';

      if (fastbootRequest) {
        var headers = fastbootRequest.headers; // Try to lookup protocol from X-Forwarded-Proto header
        // which is set by HTTPS terminating proxies.

        var forwardedProto = headers.get('X-Forwarded-Proto');
        /* Sanity check for security reasons: */

        if (forwardedProto === 'http' || forwardedProto === 'https') {
          protocol = forwardedProto + ':';
        } else {
          protocol = fastbootRequest.get('protocol');
        }
      } else {
        protocol = window.location.protocol;
      }

      return protocol;
    },
    absolutizeUrl: function absolutizeUrl(url) {
      if (url.startsWith('//')) {
        return this.protocol() + url;
      } else if (url.startsWith('/')) {
        return this.baseUrl() + url;
      } else {
        return url;
      }
    },
    apiRootUrl: Ember.computed('env.apiRootUrl', function () {
      if (this.get('isFastBoot')) {
        return window.FASTBOOT_API_URL || this.absolutizeUrl(this.get('env.apiRootUrl'));
      } else {
        return this.get('env.apiRootUrl');
      }
    }),
    apiPrefixPath: Ember.computed('apiRootUrl', function () {
      return (0, _parseUrl.default)(this.get('apiRootUrl')).pathname;
    }),
    apiHost: Ember.computed('apiRootUrl', function () {
      return (0, _parseUrl.default)(this.get('apiRootUrl')).origin;
    }),
    hreflangRootUrl: Ember.computed('env.hreflangRootUrl', function () {
      if (this.get('isFastBoot')) {
        return window.FASTBOOT_HREFLANG_URL || this.absolutizeUrl(this.get('env.hreflangRootUrl'));
      } else {
        return this.get('env.hreflangRootUrl');
      }
    }),
    cmsRootUrl: Ember.computed('env.cmsRootUrl', function () {
      if (this.get('isFastBoot')) {
        return window.FASTBOOT_CMS_URL || this.absolutizeUrl(this.get('env.cmsRootUrl'));
      } else {
        return this.get('env.cmsRootUrl');
      }
    }),
    cmsPrefixPath: Ember.computed('cmsRootUrl', function () {
      return (0, _parseUrl.default)(this.get('cmsRootUrl')).pathname;
    }),
    cmsHost: Ember.computed('cmsRootUrl', function () {
      return (0, _parseUrl.default)(this.get('cmsRootUrl')).origin;
    }),
    localeUrl: Ember.computed('tenantConfig.localeUrl', function () {
      if (this.get('isFastBoot')) {
        return this.absolutizeUrl(this.get('tenantConfig.localeUrl'));
      } else {
        return this.get('tenantConfig.localeUrl');
      }
    }),
    apiNamespace: Ember.computed('apiPrefixPath', 'currentLocale', function () {
      return "".concat(this.get('apiPrefixPath')).concat(this.get('currentLocale'), "/bito").replace(/^\//, '');
    }),
    // todo: find place for this
    productsPerPage: 30,
    finishedSpaRendering: false,
    // dynamic vatID validation w/ default from config (aka env)
    validateVatID: _environment.default.tenant.validateVatID
  });

  _exports.default = _default;
});