define("bito-shop-frontend/components/sorting-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label class="toolbar__label">{{t "taxons.sorting"}}:</label>
  
  <div class="toolbar__sorting select">
    <input id="select-sorting-accordion" name="select-sorting-accordion" type="checkbox">
    <label for="select-sorting-accordion" class="select__btn">{{this.activeSortingName}}</label>
  
    <div class="select__content">
      {{#each @sortingOptions as |sortingOption|}}
        <div class="pick">
          <input
             id={{sortingOption.id}}
             checked={{eq sortingOption.id @sortingQuery}}
             type="radio"
             name="sorting-group"
             {{on "change" (fn (action 'updateSorting') sortingOption.id)}}
             data-test-sorting="checkbox"
          />
          <label for={{sortingOption.id}}>{{sortingOption.name}}</label>
        </div>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "ajcDV+zq",
    "block": "{\"symbols\":[\"sortingOption\",\"@sortingQuery\",\"@sortingOptions\"],\"statements\":[[10,\"label\"],[14,0,\"toolbar__label\"],[12],[1,[30,[36,4],[\"taxons.sorting\"],null]],[2,\":\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"toolbar__sorting select\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,1,\"select-sorting-accordion\"],[14,3,\"select-sorting-accordion\"],[14,4,\"checkbox\"],[12],[13],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"select-sorting-accordion\"],[14,0,\"select__btn\"],[12],[1,[32,0,[\"activeSortingName\"]]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"select__content\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"pick\"],[12],[2,\"\\n        \"],[11,\"input\"],[16,1,[32,1,[\"id\"]]],[16,\"checked\",[30,[36,0],[[32,1,[\"id\"]],[32,2]],null]],[24,3,\"sorting-group\"],[24,4,\"radio\"],[4,[38,3],[\"change\",[30,[36,2],[[30,[36,1],[[32,0],\"updateSorting\"],null],[32,1,[\"id\"]]],null]],null],[12],[13],[2,\"\\n        \"],[10,\"label\"],[15,\"for\",[32,1,[\"id\"]]],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"action\",\"fn\",\"on\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/components/sorting-filter.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.sortingOptions = this.sortingOptions || [];
    },
    filterService: Ember.inject.service('productFilter'),
    tagName: 'div',
    classNames: ['toolbar__widget'],
    sortingQuery: '',
    activeSortingName: Ember.computed('sortingQuery', function () {
      var _this = this;

      return this.get('sortingOptions').find(function (option) {
        return option.id === _this.get('sortingQuery');
      }).name;
    }),
    actions: {
      updateSorting: function updateSorting(orderBy) {
        this.set('sortingQuery', orderBy);
      }
    }
  }));

  _exports.default = _default;
});