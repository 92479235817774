define("bito-shop-frontend/components/variant-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="downloads__item">
    <h3 class="downloads__headline">{{{or download.documentType "&nbsp;"}}}</h3>
    <div class="downloads__content">
      <img src={{src-set download.url w="190" h="270" fm="pjpg" chromasub="444"}}
           srcset="{{src-set download.url w="380" h="540" fm="pjpg" chromasub="444"}} 2x"
           class="downloads__image"
           loading="lazy"
           alt={{download.altText}}>
  
      <a href={{downloadUrl}} class="downloads__btn btn {{if download.isPDF "btn__green--pdf" "btn__green"}}" download>
        <span>
          {{t "products.download"}}
          <span class="downloads__info">
            ({{if isPDF "PDF | " ""}}{{format-number download.sizeInMB allowEmpty=true}} MB)
          </span>
        </span>
      </a>
    </div>
  </div>
  
  
  */
  {
    "id": "xsh3hiAq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"downloads__item\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"downloads__headline\"],[12],[2,[30,[36,1],[[35,0,[\"documentType\"]],\"&nbsp;\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"downloads__content\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,[36,2],[[35,0,[\"url\"]]],[[\"w\",\"h\",\"fm\",\"chromasub\"],[\"190\",\"270\",\"pjpg\",\"444\"]]]],[15,\"srcset\",[31,[[30,[36,2],[[35,0,[\"url\"]]],[[\"w\",\"h\",\"fm\",\"chromasub\"],[\"380\",\"540\",\"pjpg\",\"444\"]]],\" 2x\"]]],[14,0,\"downloads__image\"],[14,\"loading\",\"lazy\"],[15,\"alt\",[34,0,[\"altText\"]]],[12],[13],[2,\"\\n\\n    \"],[10,\"a\"],[15,6,[34,3]],[15,0,[31,[\"downloads__btn btn \",[30,[36,4],[[35,0,[\"isPDF\"]],\"btn__green--pdf\",\"btn__green\"],null]]]],[14,\"download\",\"\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,5],[\"products.download\"],null]],[2,\"\\n        \"],[10,\"span\"],[14,0,\"downloads__info\"],[12],[2,\"\\n          (\"],[1,[30,[36,4],[[35,6],\"PDF | \",\"\"],null]],[1,[30,[36,7],[[35,0,[\"sizeInMB\"]]],[[\"allowEmpty\"],[true]]]],[2,\" MB)\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"download\",\"or\",\"src-set\",\"downloadUrl\",\"if\",\"t\",\"isPDF\",\"format-number\"]}",
    "moduleName": "bito-shop-frontend/components/variant-download.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    download: null,
    downloadUrl: Ember.computed('download', function () {
      var url = this.get('download.url');
      var filename = window.encodeURIComponent(this.get('download.originalFilename'));
      if (filename) return url + '?dl=' + filename;else return '';
    }),
    isPDF: Ember.computed('download', function () {
      return this.get('download.contentType') === 'application/pdf';
    })
  }));

  _exports.default = _default;
});