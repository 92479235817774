define("bito-shop-frontend/templates/checkout/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SpJBUEsn",
    "block": "{\"symbols\":[],\"statements\":[[8,\"checkout-confirm\",[],[[\"@order\",\"@billAddress\",\"@shipAddress\",\"@paymentMethod\",\"@shippingMethod\",\"@transitionCheckoutState\"],[[32,0,[\"mystique\",\"currentOrder\"]],[32,0,[\"mystique\",\"currentOrder\",\"billAddress\"]],[32,0,[\"mystique\",\"currentOrder\",\"shipAddress\"]],[32,0,[\"mystique\",\"currentOrder\",\"activePayment\",\"paymentMethod\"]],[32,0,[\"mystique\",\"currentOrder\",\"shippingMethod\"]],[30,[36,0],[\"transitionCheckoutState\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "moduleName": "bito-shop-frontend/templates/checkout/confirm.hbs"
  });

  _exports.default = _default;
});