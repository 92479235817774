define("bito-shop-frontend/components/x-hrefs", ["exports", "jquery", "bito-shop-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "f5/I380Z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/components/x-hrefs.hbs"
  });

  var isSimpleClick = Ember.ViewUtils.isSimpleClick;

  function isRelativeLink(el) {
    return el && el.tagName === 'A' && el.href && el.host === document.location.host && (el.target === "" || el.target === "_self" || el.target === "_top");
  }

  function getRelativeUri(link) {
    return "".concat(link.pathname.replace("/".concat(_environment.default.localePrefix), '')).concat(link.search).concat(link.hash);
  }

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'span',
    click: function click(e) {
      var el = e.target;
      var link;

      if (el.tagName === 'A') {
        link = el;
      } else {
        link = (0, _jquery.default)(el).parents().filter('a')[0];
      }

      if (isRelativeLink(link) && isSimpleClick(e)) {
        e.preventDefault();
        e.stopPropagation();
        var router = Ember.getOwner(this).lookup('router:main');
        var uri = getRelativeUri(link);
        router.transitionTo(uri);
      }
    }
  }));

  _exports.default = _default;
});