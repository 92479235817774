define("bito-shop-frontend/components/breadcrumbs-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="grid grid--full breadcrumb breadcrumb--fixed">
    <div class="grid__col-24 breadcrumb__inner">
      <BreadCrumbs
        @tagName="ul"
        @crumbClass="breadcrumb__item"
        @labelClass="breadcrumb__label"
        @linkClass="breadcrumb__link"
        @classNames="breadcrumb__list"
      />
    </div>
  </div>
  
  */
  {
    "id": "6b8gCnov",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid grid--full breadcrumb breadcrumb--fixed\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"grid__col-24 breadcrumb__inner\"],[12],[2,\"\\n    \"],[8,\"bread-crumbs\",[],[[\"@tagName\",\"@crumbClass\",\"@labelClass\",\"@linkClass\",\"@classNames\"],[\"ul\",\"breadcrumb__item\",\"breadcrumb__label\",\"breadcrumb__link\",\"breadcrumb__list\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/components/breadcrumbs-container.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});