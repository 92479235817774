define("bito-shop-frontend/components/category-slider", ["exports", "bito-shop-frontend/components/base-slider", "bito-shop-frontend/mixins/slider-with-current-slide", "bito-shop-frontend/mixins/slider-with-pagination", "bito-shop-frontend/mixins/slider-with-arrows"], function (_exports, _baseSlider, _sliderWithCurrentSlide, _sliderWithPagination, _sliderWithArrows) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="sample-swiper-pagination">
    {{t "slider.count" page=this.currentSlide total=this.totalSlides htmlSafe=true}}
  </div>
  <div class="sample sample--{{modifier}} swiper-container">
    <div class="swiper-wrapper">
      {{#each collection as |taxon idx|}}
        <CategorySliderItem @taxon={{taxon}}
                            @hideCounter={{hideCounter}}
                            @filters={{filters}}
                            @idx={{idx}}
                            @currentSlide={{currentSlide}}
                            @slidesToScroll={{slidesToScroll}} />
      {{/each}}
    </div>
  </div>
  
  <SliderArrows @sliderIsActive={{sliderIsActive}}/>
  
  */
  {
    "id": "x/FTHJu7",
    "block": "{\"symbols\":[\"taxon\",\"idx\"],\"statements\":[[10,\"div\"],[14,0,\"sample-swiper-pagination\"],[12],[2,\"\\n  \"],[1,[30,[36,4],[\"slider.count\"],[[\"page\",\"total\",\"htmlSafe\"],[[32,0,[\"currentSlide\"]],[32,0,[\"totalSlides\"]],true]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"sample sample--\",[34,5],\" swiper-container\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-wrapper\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"category-slider-item\",[],[[\"@taxon\",\"@hideCounter\",\"@filters\",\"@idx\",\"@currentSlide\",\"@slidesToScroll\"],[[32,1],[34,0],[34,1],[32,2],[34,2],[34,3]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"slider-arrows\",[],[[\"@sliderIsActive\"],[[34,9]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hideCounter\",\"filters\",\"currentSlide\",\"slidesToScroll\",\"t\",\"modifier\",\"collection\",\"-track-array\",\"each\",\"sliderIsActive\"]}",
    "moduleName": "bito-shop-frontend/components/category-slider.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, _baseSlider.default.extend(_sliderWithPagination.default, _sliderWithCurrentSlide.default, _sliderWithArrows.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.sliderConfig = {
        observer: true,
        speed: 400,
        spaceBetween: 25,
        breakpoints: {
          499: {
            slidesPerView: 1,
            slidesPerGroup: 1
          },
          749: {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          1099: {
            slidesPerView: 3,
            slidesPerGroup: 3
          },
          1449: {
            slidesPerView: 4,
            slidesPerGroup: 4
          },
          1749: {
            slidesPerView: 5,
            slidesPerGroup: 5
          }
        }
      };
    },
    tagName: 'div',
    classNames: ['slider-item'],
    filters: '{}',
    modifier: 'slider',
    hideCounter: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    sliderSelector: Ember.computed(function () {
      return '#' + this.get('elementId') + ' .swiper-container';
    }),
    singleSlideCallback: function singleSlideCallback() {
      this.$('.swiper-wrapper').addClass('is-centered');
    }
  }));

  _exports.default = _default;
});