define("bito-shop-frontend/mixins/slider-with-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    collectionByParity: Ember.computed('collection', function () {
      var collectionByParity = this.get('collection').reduce(function (obj, slide, idx) {
        var parity = idx % 2 ? 'even' : 'odd';
        slide.index = idx;
        obj[parity].push(slide);
        return obj;
      }, {
        odd: [],
        even: []
      });
      return collectionByParity;
    }),
    actions: {
      slideTo: function slideTo(id) {
        var sliderInstance = this.get('sliderInstance');

        if (sliderInstance) {
          sliderInstance.slideTo(id);
        }
      }
    }
  });

  _exports.default = _default;
});