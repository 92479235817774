define("bito-shop-frontend/routes/virtual-products", ["exports", "bito-shop-frontend/routes/products"], function (_exports, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _products.default.extend({
    templateName: 'products',
    controllerName: 'virtual-products'
  });

  _exports.default = _default;
});