define("bito-shop-frontend/routes/checkout/index", ["exports", "ember-scroll-operator/mixins/scroll-operator"], function (_exports, _scrollOperator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollOperator.default, {
    beforeModel: function beforeModel() {
      if (!this.mystique.get('needsToPickCheckoutType')) {
        this.mystique.resetCheckoutType();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});