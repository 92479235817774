define("bito-shop-frontend/routes/order-form", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('orderForm.headline')
      };
    }),
    titleToken: Ember.computed(function () {
      return this.get('intl').t('orderForm.headline');
    }),
    model: function model() {
      return this.mystique.orderForm.get('currentForm');
    }
  });

  _exports.default = _default;
});