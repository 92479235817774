define("bito-shop-frontend/templates/checkout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kf58Rler",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid grid--max\"],[12],[2,\"\\n  \"],[8,\"checkout-breadcrumb\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"checkout panel panel--nogap\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"grid grid--max\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"footer-checkout\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "bito-shop-frontend/templates/checkout.hbs"
  });

  _exports.default = _default;
});