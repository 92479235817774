define("bito-shop-frontend/components/subfooter-logos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel u-background-alabaster">
    <div class="grid grid--max">
      <h4 class="grid__col-24 text-medium">
        {{t "subfooter_logos.headline"}}
      </h4>
    </div>
    <div class="grid grid--max subfooter-logos">
      {{#each this.images as |image|}}
        <div class="subfooter-logos__item">
          <picture>
            <source srcset={{src-set (cdn-src image.url)
                               (hash h="150" descr="1.5x")
                               fm="png8"
                               q="70"}}>
            <img src={{src-set (cdn-src image.url) h="100"}}
                 srcset={{src-set (cdn-src image.url)
                            (hash h="150" descr="1.5x")
                            fm="png8"
                            q="70"}}
                 loading="lazy"
                 alt={{image.alt}}>
          </picture>
        </div>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "QRuwZZfw",
    "block": "{\"symbols\":[\"image\"],\"statements\":[[10,\"div\"],[14,0,\"panel u-background-alabaster\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"grid grid--max\"],[12],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"grid__col-24 text-medium\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"subfooter_logos.headline\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"grid grid--max subfooter-logos\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"images\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"subfooter-logos__item\"],[12],[2,\"\\n        \"],[10,\"picture\"],[12],[2,\"\\n          \"],[10,\"source\"],[15,\"srcset\",[30,[36,2],[[30,[36,1],[[32,1,[\"url\"]]],null],[30,[36,0],null,[[\"h\",\"descr\"],[\"150\",\"1.5x\"]]]],[[\"fm\",\"q\"],[\"png8\",\"70\"]]]],[12],[13],[2,\"\\n          \"],[10,\"img\"],[15,\"src\",[30,[36,2],[[30,[36,1],[[32,1,[\"url\"]]],null]],[[\"h\"],[\"100\"]]]],[15,\"srcset\",[30,[36,2],[[30,[36,1],[[32,1,[\"url\"]]],null],[30,[36,0],null,[[\"h\",\"descr\"],[\"150\",\"1.5x\"]]]],[[\"fm\",\"q\"],[\"png8\",\"70\"]]]],[14,\"loading\",\"lazy\"],[15,\"alt\",[32,1,[\"alt\"]]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"cdn-src\",\"src-set\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/components/subfooter-logos.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.images = this.get('mystique.tenantConfig.subfooterLogos');
    }
  }));

  _exports.default = _default;
});