define("bito-shop-frontend/session-stores/application", ["exports", "ember-simple-auth/session-stores/local-storage", "bito-shop-frontend/constants"], function (_exports, _localStorage, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localStorage.default.extend({
    key: _constants.localStorageKey,
    persist: function persist() {
      if (!this.get('_isFastBoot')) {
        return this._super.apply(this, arguments);
      } else {
        return Ember.RSVP.reject();
      }
    },
    restore: function restore() {
      if (!this.get('_isFastBoot')) {
        return this._super.apply(this, arguments);
      } else {
        return Ember.RSVP.reject();
      }
    }
  });

  _exports.default = _default;
});