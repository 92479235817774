define("bito-shop-frontend/routes/checkout/processing", ["exports", "ember-scroll-operator/mixins/scroll-operator", "bito-shop-frontend/routes/checkout-step"], function (_exports, _scrollOperator, _checkoutStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _checkoutStep.default.extend(_scrollOperator.default, {
    resetController: function resetController()
    /*controller, isExiting, transition*/
    {
      this.mystique.get('checkouts').closePaymentWindow();
    },
    redirect: function redirect() {
      var needsPopup = this.mystique.get('currentOrder.hasPopupIntegration');
      var popupClosed = !window.paymentWindow || window.paymentWindow.closed;

      if (needsPopup && popupClosed) {
        this.mystique.get('checkouts').transition('confirm');
        this.transitionTo('checkout.confirm');
        this.get('mystique.toast').error('Bitte schließe Deinen Kauf über "ZAHLUNGSPFLICHTIG BESTELLEN" ab oder kehre zum Shop zurück, um Deinen Kauf abzubrechen.');
      }
    }
  });

  _exports.default = _default;
});