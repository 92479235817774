define("bito-shop-frontend/components/user-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{@address.title}}<br>
  {{@address.fullName}}<br>
  {{@address.company}}<br>
  {{@address.address1}}<br>
  {{@address.zipcode}} {{@address.city}}<br>
  {{@address.country.name}}
  
  */
  {
    "id": "otlc0k8Z",
    "block": "{\"symbols\":[\"@address\"],\"statements\":[[1,[32,1,[\"title\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[1,[32,1,[\"fullName\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[1,[32,1,[\"company\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[1,[32,1,[\"address1\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[1,[32,1,[\"zipcode\"]]],[2,\" \"],[1,[32,1,[\"city\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[1,[32,1,[\"country\",\"name\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/components/user-address.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});