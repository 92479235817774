define("bito-shop-frontend/routes/configurator-taxons", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // this might be merged w/ taxon route at some point
  var _default = _baseRoute.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.breadCrumbs = [];
    },
    titleToken: function titleToken(model) {
      var taxon = model.taxon;
      var intl = this.get('intl');

      if (taxon.get('parentId')) {
        return intl.t('base.taxonBuyOnline', {
          taxon: model.taxon.get('name')
        });
      } else {
        return intl.t('taxons.rootTaxonTitle');
      }
    },
    model: function model(params) {
      var configuratorPermalink = params.configurator_permalink.replace(/\/$/, '');
      var taxon = this.store.findRecord('taxon', configuratorPermalink); // gotcha: ember has to reload configurators client side, b/c ids are note referenced
      // on taxon (we use link to find configurators)

      var configurators = taxon.then(function (t) {
        return t.get('configurators');
      });
      return Ember.RSVP.hash({
        configurators: configurators,
        taxon: taxon
      });
    },
    afterModel: function afterModel(model) {
      var breadCrumbs = model.taxon.get('breadcrumbs').sort(function (p1, p2) {
        return p1.permalink.split('/').length > p2.permalink.split('/').length ? 1 : -1;
      });
      this.set('breadCrumbs', breadCrumbs); // reset forceScrollPos

      this.set('forceScrollPos', -1);
    }
  });

  _exports.default = _default;
});