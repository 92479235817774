define("bito-shop-frontend/routes/checkout", ["exports", "jquery", "bito-shop-frontend/routes/base-route"], function (_exports, _jquery, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _baseRoute.default.extend({
    router: Ember.inject.service(),
    layout: 'layout-checkout',
    // has no viewState support!
    titleToken: 'Checkout',
    resetController: function resetController(_, isExiting, transition) {
      var _this = this;

      this._super.apply(this, arguments);

      if (isExiting && this.mystique.get('currentOrder')) {
        transition.finally(function () {
          _this.mystique.resetCheckoutType();

          _this.mystique.get('checkouts').transition('cart');
        });
      }
    },
    onCheckoutStateChange: Ember.observer('mystique.checkouts.currentState', function () {
      var currentOrder = this.mystique.get('currentOrder');
      if (!currentOrder || !this._isCheckoutRoute()) return;
      var allCheckoutStates = ['cart'].concat(_toConsumableArray(currentOrder.get('checkoutSteps'))) || [];

      if (allCheckoutStates.includes(this.mystique.get('checkouts.currentState'))) {
        this.redirect();
      }
    }),
    onCheckoutTypeChange: Ember.observer('mystique.needsToPickCheckoutType', function () {
      if (this._isCheckoutRoute()) {
        this.redirect();
      }
    }),
    _redirectToOrderState: function _redirectToOrderState() {
      var orderState = this.mystique.get('currentOrder.state');
      var viewState = this.mystique.get('checkouts.currentState');

      if (orderState === 'cart') {
        this.transitionTo('cart');
      } else if (this.mystique.get('needsToPickCheckoutType')) {
        this.transitionTo('checkout.index');
      } else {
        this.transitionTo("checkout.".concat(viewState));
        this.mystique.trigger('checkoutStateDidChange', this.mystique.get('currentOrder'), viewState);
      }
    },
    redirect: function redirect()
    /* model */
    {
      var currentOrder = this.mystique.get('currentOrder');

      if (currentOrder) {
        this._redirectToOrderState();
      } else {
        this.transitionTo('taxons', this.mystique.get('tenantConfig.rootTaxon'));
      }
    },
    actions: {
      transitionCheckoutState: function transitionCheckoutState(state) {
        var _this2 = this;

        var currentState = this.mystique.get('checkouts.currentState');
        var allStates = this.mystique.get('currentOrder.checkoutSteps') || [];
        var stateDiff = allStates.indexOf(state) - allStates.indexOf(currentState);
        var orderTransition;

        if (stateDiff > 1) {
          orderTransition = this.mystique.get('checkouts').transition();

          for (var i = 2; i <= stateDiff; i++) {
            orderTransition = orderTransition.then(function () {
              return _this2.mystique.get('checkouts').transition();
            });
          }
        } else {
          orderTransition = this.mystique.get('checkouts').transition(state);
        }

        return orderTransition.finally(function () {
          _this2.mystique.trigger('checkoutStateWillChange', _this2.mystique.get('currentOrder'), currentState);
        });
      },
      setCheckoutType: function setCheckoutType(type) {
        this.mystique.setCheckoutType(type);
        this.redirect();
      },
      submitLoginForm: function submitLoginForm() {
        // form-login handles submit via custom authentication
        (0, _jquery.default)('#form-login button').first().click();
      }
    },
    _isCheckoutRoute: function _isCheckoutRoute() {
      var currentRouteName = this.router.currentRouteName;

      if (currentRouteName) {
        return currentRouteName.includes('checkout');
      } else {
        return false;
      }
    }
  });

  _exports.default = _default;
});