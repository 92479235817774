define("bito-shop-frontend/routes/account/address/new", ["exports", "bito-shop-frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('account.addNewAddress')
      };
    }),
    model: function model()
    /*params*/
    {
      var isos = this.mystique.get('tenantConfig.countries') || [];
      return Ember.RSVP.hash({
        account: this.modelFor('account'),
        address: this.store.createRecord('address'),
        countries: this.store.query('country', {
          isos: isos
        })
      });
    },
    afterModel: function afterModel(model) {
      // set default country
      var defaultCountryISO = this.mystique.get('tenantConfig.defaultCountry');

      if (defaultCountryISO) {
        model.address.set('country', model.countries.findBy('iso', defaultCountryISO));
      }
    },
    actions: {
      onSave: function onSave() {
        this.transitionTo('account.address');
      }
    }
  });

  _exports.default = _default;
});