define("bito-shop-frontend/components/controlled-sliders-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield model (action "setSliderInstance") activeSlideIdx}}
  
  */
  {
    "id": "m5wTeP6/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,2],[30,[36,1],[[32,0],\"setSliderInstance\"],null],[35,0]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"activeSlideIdx\",\"action\",\"model\"]}",
    "moduleName": "bito-shop-frontend/components/controlled-sliders-container.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'div',
    model: null,
    // variant
    thumbnailSlider: null,
    // swiper instance
    mainSlider: null,
    // swiper instance
    actions: {
      setSliderInstance: function setSliderInstance(type, slider) {
        this.set(type, slider);
      },
      setupSliderControls: function setupSliderControls(slider1, slider2) {
        slider1.params.control = slider2;
        slider2.params.control = slider1;
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var mainSlider = this.get('mainSlider');
      var thumbnailSlider = this.get('thumbnailSlider');

      if (mainSlider && thumbnailSlider) {
        this.send('setupSliderControls', mainSlider, thumbnailSlider);
      }
    }
  }));

  _exports.default = _default;
});