define("bito-shop-frontend/components/modal-slider", ["exports", "bito-shop-frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a role="button" class="close" {{action "closeModal"}}>
    <span class="close__txt">{{t "base.closeMenu"}}</span>
    <XIcon @id="times-solid" @class="icon--huge" />
  </a>
  
  
  <div class="layer__inner--scrollable">
    <ProductModalSlider
        @collection={{model.variant.allSliderImages}}
        @slidesCount={{sum model.variant.allSliderImages.length (if video 1 0)}}
        @startSlideIndex={{model.activeSlideIndex}}
        @isActive={{isActive}}
        @video={{model.video}} />
  </div>
  
  
  */
  {
    "id": "vIHZJAKg",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,\"role\",\"button\"],[24,0,\"close\"],[4,[38,0],[[32,0],\"closeModal\"],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"close__txt\"],[12],[1,[30,[36,1],[\"base.closeMenu\"],null]],[13],[2,\"\\n  \"],[8,\"x-icon\",[],[[\"@id\",\"@class\"],[\"times-solid\",\"icon--huge\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"layer__inner--scrollable\"],[12],[2,\"\\n  \"],[8,\"product-modal-slider\",[],[[\"@collection\",\"@slidesCount\",\"@startSlideIndex\",\"@isActive\",\"@video\"],[[34,2,[\"variant\",\"allSliderImages\"]],[30,[36,5],[[35,2,[\"variant\",\"allSliderImages\",\"length\"]],[30,[36,4],[[35,3],1,0],null]],null],[34,2,[\"activeSlideIndex\"]],[34,6],[34,2,[\"video\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"model\",\"video\",\"if\",\"sum\",\"isActive\"]}",
    "moduleName": "bito-shop-frontend/components/modal-slider.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_modal.default, {
    skipLinks: Ember.inject.service(),
    tagName: 'div',
    classNames: ['layer__inner', 'layer__inner--full'],
    actions: {
      closeModal: function closeModal() {
        this.get('skipLinks').deactivate();
        window.SovidoHandler && window.SovidoHandler.players.forEach(function (player) {
          return player.player.pause();
        });
      }
    }
  }));

  _exports.default = _default;
});