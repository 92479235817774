define("bito-shop-frontend/components/load-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      window.svg4everybody();
      this.mystique.set('finishedSpaRendering', true);
    }
  });

  _exports.default = _default;
});