define("bito-shop-frontend/components/loader-inner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="loader__inner">
    <svg class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="1" stroke-miterlimit="10"/>
    </svg>
    <div class="loader__logo">
      <MainLogoSvg />
    </div>
  </div>
  
  */
  {
    "id": "gK0J8Sp+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loader__inner\"],[12],[2,\"\\n  \"],[10,\"svg\"],[14,0,\"circular\"],[14,\"viewBox\",\"25 25 50 50\"],[12],[2,\"\\n    \"],[10,\"circle\"],[14,0,\"path\"],[14,\"cx\",\"50\"],[14,\"cy\",\"50\"],[14,\"r\",\"20\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"1\"],[14,\"stroke-miterlimit\",\"10\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loader__logo\"],[12],[2,\"\\n    \"],[8,\"main-logo-svg\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/components/loader-inner.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});