define("bito-shop-frontend/components/slider-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{unless @sliderIsActive "hide"}}>
    <div class="slider-item__prev js-slider-prev"></div>
    <div class="slider-item__next js-slider-next"></div>
  </div>
  
  */
  {
    "id": "kX3zw+lc",
    "block": "{\"symbols\":[\"@sliderIsActive\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[[32,1],\"hide\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"slider-item__prev js-slider-prev\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"slider-item__next js-slider-next\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\"]}",
    "moduleName": "bito-shop-frontend/components/slider-arrows.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});