define("bito-shop-frontend/initializers/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var HEADDATA_PROPS = ['canonical', 'hreflangLinks', 'jsonld', 'metaDescription', 'noIndex'];

  function initialize()
  /* application */
  {
    Ember.Route.reopen({
      router: Ember.inject.service(),
      resetSkipLinks: true,
      closeOffcanvasNav: true,
      renderTemplate: function renderTemplate() {
        var skipLinks = this.mystique.skipLinks;

        this._super();

        if (this.resetSkipLinks && skipLinks.activeElement) {
          skipLinks.deactivate();
        } // TODO: quick way to close mmenu when transitioning
        // there is a nicer way to do this. find it!


        if (!this.mystique.get('fastboot.isFastBoot')) {
          if (this.closeOffcanvasNav) {
            var $mmenu = (0, _jquery.default)('.mm-menu');

            if ($mmenu.length) {
              $mmenu.data('mmenu').close();
            }
          }
        }
      },
      actions: {
        collectHeadData: function collectHeadData(headData) {
          var _this = this;

          HEADDATA_PROPS.forEach(function (propName) {
            if (headData[propName]) return; // headData already defined in higher up route

            var dataProp = _this[propName];

            if (dataProp) {
              headData[propName] = dataProp.call(_this, _this.currentModel);
            }
          });
          var topLevelRoute = this.router.currentRoute.find(function (routeInfo) {
            return routeInfo.parent === null;
          });
          var done = this.fullRouteName === topLevelRoute.name;

          if (done) {
            this._router.writeHeadData(headData);
          } else {
            return true; // keep bubbling
          }
        }
      }
    });
    Ember.Router.reopen({
      viewState: Ember.inject.service(),
      gtm: Ember.inject.service(),
      mystique: Ember.inject.service(),
      showLoadingOverlay: Ember.on('willTransition', function () {
        this.set('viewState.loadingOverlay', true);
      }),
      hideLoadingOverlay: Ember.on('didTransition', function () {
        Ember.run.schedule('afterRender', this, function () {
          this.set('viewState.loadingOverlay', false);
        });
      }),
      trackPageView: Ember.on('didTransition', function () {
        Ember.run.schedule('afterRender', this, function () {
          if (!this.mystique.isFastBoot) {
            this.gtm.addVirtualPageview(this.url, this.rootURL, document.title);
          }
        });
      }),
      headData: Ember.inject.service(),
      collectHeadDataOnDidTransition: Ember.on('didTransition', function () {
        this.send('collectHeadData', {});
      }),
      writeHeadData: function writeHeadData(headData) {
        this.headData.setProperties(HEADDATA_PROPS.reduce(function (mem, prop) {
          mem[prop] = mem[prop] || '';
          return mem;
        }, headData));
      },
      setTitle: function setTitle(title) {
        this.headData.set('title', title);
      }
    });
  }

  var _default = {
    name: 'reset-scroll',
    initialize: initialize
  };
  _exports.default = _default;
});