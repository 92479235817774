define("bito-shop-frontend/lib/prudsys-tracking/main", ["exports", "bito-shop-frontend/lib/local-storage"], function (_exports, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _VALID_ENDPOINTS;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TRACKER = 'tracker';
  var RDE = 'rde';
  var VALID_ENDPOINTS = (_VALID_ENDPOINTS = {}, _defineProperty(_VALID_ENDPOINTS, TRACKER, 'event'), _defineProperty(_VALID_ENDPOINTS, RDE, 'core/recommendation'), _VALID_ENDPOINTS); // service has to be activated in PRUDSYS_SVCS env variable

  var RECO_SERVICES = {
    START_PAGE: 'startPage',
    SEARCH_PAGE: 'searchPage',
    CONFIGURATOR_DETAIL_PAGE: 'configuratorDetailPage',
    PRODUCT_DETAIL_PAGE: 'productDetailPage',
    DIRECT_ORDER: 'directOrder',
    CATEGORY_PAGE: 'categoryPage',
    CATEGORY_PAGE_SORTING: 'categoryPageSorting',
    CATEGORY_LAYER: 'categoryLayer',
    BASKET: 'basket',
    MAIN_NAVIGATION: 'mainNavigation',
    ERROR_PAGE: 'errorPage',
    ALL_CATEGORIES: 'allCategories'
  };
  var _default = {
    init: function init(_ref) {
      var _targetEndpoints;

      var httpClient = _ref.httpClient,
          storageKey = _ref.storageKey,
          host = _ref.host,
          recommendationEngineID = _ref.recommendationEngineID,
          _ref$port = _ref.port,
          port = _ref$port === void 0 ? '' : _ref$port,
          _ref$userID = _ref.userID,
          userID = _ref$userID === void 0 ? '' : _ref$userID,
          _ref$disabled = _ref.disabled,
          disabled = _ref$disabled === void 0 ? true : _ref$disabled,
          locationID = _ref.locationID;
      // required arguments
      if (!httpClient) throw 'Missing Argument: httpClient';
      if (!storageKey) throw 'Missing Argument: storageKey';
      if (!host) throw 'Missing Argument: host';
      if (!recommendationEngineID) throw 'Missing Argument: recommendationEngineID';
      if (!locationID) throw 'Missing Argument: locationID';
      var sessionID = readSession(storageKey) || setupSession(storageKey);
      var targetEndpoints = (_targetEndpoints = {}, _defineProperty(_targetEndpoints, TRACKER, buildBaseUrl(host, recommendationEngineID, port, TRACKER)), _defineProperty(_targetEndpoints, RDE, buildBaseUrl(host, recommendationEngineID, port, RDE)), _targetEndpoints);
      var config = {
        host: host,
        port: port,
        recommendationEngineID: recommendationEngineID,
        userID: userID,
        sessionID: sessionID,
        targetEndpoints: targetEndpoints,
        disabled: disabled,
        storageKey: storageKey,
        locationID: locationID
      };
      var prudsysClient = {
        serviceList: RECO_SERVICES,

        set userID(userID) {
          config.userID = userID;
        },

        enableTracking: function enableTracking() {
          config.disabled = false;
        },
        disableTracking: function disableTracking() {
          config.disabled = true;
        },
        trackProductView: trackProductView,
        trackCategoryView: trackCategoryView,
        trackBasketAddition: trackBasketAddition,
        trackOrderCompletion: trackOrderCompletion,
        trackSearchTerm: trackSearchTerm,
        trackProductClick: trackProductClick,
        trackCategoryClick: trackCategoryClick,
        fetchRecommendations: fetchRecommendations
      };
      return Object.freeze(prudsysClient); // recommendation requests

      function fetchRecommendations(svcName, args) {
        switch (svcName) {
          case RECO_SERVICES.START_PAGE:
            return fetchStartPageRecommendations(args);

          case RECO_SERVICES.SEARCH_PAGE:
            return fetchSearchPageRecommendations(args);

          case RECO_SERVICES.CONFIGURATOR_DETAIL_PAGE:
            return fetchConfiguratorDetailPageRecommendations(args);

          case RECO_SERVICES.PRODUCT_DETAIL_PAGE:
            return fetchProductDetailPageRecommendations(args);

          case RECO_SERVICES.CATEGORY_PAGE_SORTING:
            return fetchProductDetailSortingPageRecommendations(args);

          case RECO_SERVICES.DIRECT_ORDER:
            return fetchDirectOrderRecommendations(args);

          case RECO_SERVICES.CATEGORY_PAGE:
            return fetchCategoryPageRecommendations(args);

          case RECO_SERVICES.CATEGORY_LAYER:
            return fetchCategoryLayerRecommendations(args);

          case RECO_SERVICES.BASKET:
            return fetchBasketRecommendations(args);

          case RECO_SERVICES.MAIN_NAVIGATION:
            return fetchMainNavigationRecommendations(args);

          case RECO_SERVICES.ERROR_PAGE:
            return fetchErrorPageRecommendations();

          case RECO_SERVICES.ALL_CATEGORIES:
            return fetchCategoriesAllProducts(args);

          default:
            throw new Error('Unrecognized Reco Service.');
        }
      }

      function fetchErrorPageRecommendations() {
        var url = buildRecommendationUrl('Errorseite', {});
        return get(url);
      }

      function fetchCategoriesAllProducts() {
        var url = buildRecommendationUrl('Kategorieseite-Alle-Produkte');
        return get(url);
      }

      function fetchMainNavigationRecommendations(args) {
        var url = buildRecommendationUrl('categorylayer', {
          // optional
          cid: args.categoryID
        });
        return get(url);
      }

      function fetchStartPageRecommendations(args) {
        var url = buildRecommendationUrl('startpage', {
          // required
          cid1: args.categoryID1,
          cid2: args.categoryID2,
          cid3: args.categoryID3,
          // optional
          blacklistfilter: args.blacklistFilter,
          'blacklistfilter.cat': args.blacklistFilterCat,
          'blacklistfilter.logic': args.blacklistFilterLogic,
          'blacklistfilter.cat.logic': args.blacklistFilterCatLogic,
          userid: config.userID,
          locationid: config.locationID
        });
        return get(url);
      }

      function fetchSearchPageRecommendations(args) {
        var url = buildRecommendationUrl('searchpage', {
          // required
          searchterm: normalizeSearchTerm(args.searchTerm),
          // optional
          blacklistfilter: args.blacklistFilter,
          'blacklistfilter.logic': args.blacklistFilterLogic,
          userid: config.userID,
          locationid: config.locationID
        });
        return get(url);
      }

      function fetchConfiguratorDetailPageRecommendations(args) {
        var url = buildRecommendationUrl('configuratordetailpage', {
          // required
          plist: args.productList,
          accessory: args.accessory,
          // optional
          blacklistfilter: args.blacklistFilter,
          'blacklistfilter.logic': args.blacklistFilterLogic,
          userid: config.userID,
          locationid: config.locationID
        });
        return get(url);
      }

      function fetchProductDetailPageRecommendations(args) {
        var url = buildRecommendationUrl('productdetailpage', {
          // required
          pid: args.productID,
          cid: args.taxonID,
          accessory: args.accessory,
          // optional
          blacklistfilter: args.blacklistFilter,
          'blacklistfilter.logic': args.blacklistFilterLogic,
          userid: config.userID,
          locationid: config.locationID
        });
        return get(url);
      }

      function fetchProductDetailSortingPageRecommendations(args) {
        // beware: productdetailpage_sorting was misnamed in prudsys!
        var url = buildRecommendationUrl('productdetailpage_sorting', {
          // required
          cid: args.categoryID
        });
        return get(url);
      }

      function fetchDirectOrderRecommendations(args) {
        var url = buildRecommendationUrl('directorder', {
          // required
          plist: args.productList,
          // optional
          blacklistfilter: args.blacklistFilter,
          'blacklistfilter.logic': args.blacklistFilterLogic,
          userid: config.userID,
          locationid: config.locationID
        });
        return get(url);
      }

      function fetchCategoryPageRecommendations(args) {
        var url = buildRecommendationUrl('categorypage', {
          // required
          filterCategory: args.filterCategory,
          cid: args.categoryID,
          // optional
          blacklistfilter: args.blacklistFilter,
          'blacklistfilter.cat': args.blacklistFilterCat,
          'blacklistfilter.logic': args.blacklistFilterLogic,
          'blacklistfilter.cat.logic': args.blacklistFilterCatLogic,
          userid: config.userID,
          locationid: config.locationID
        });
        return get(url);
      }

      function fetchCategoryLayerRecommendations(args) {
        var url = buildRecommendationUrl('categorylayer', {
          // required
          categoryid: args.categoryID,
          // optional
          blacklistfilter: args.blacklistFilter,
          'blacklistfilter.logic': args.blacklistFilterLogic,
          userid: config.userID,
          locationid: config.locationID
        });
        return get(url);
      }

      function fetchBasketRecommendations(args) {
        var url = buildRecommendationUrl('basket', {
          // required
          plist: args.productList,
          value: args.value,
          upperBorder: args.value + 30,
          // optional
          blacklistfilter: args.blacklistFilter,
          'blacklistfilter.logic': args.blacklistFilterLogic,
          userid: config.userID,
          locationid: config.locationID
        });
        return get(url);
      } // tracking requests


      function trackProductView(productID) {
        var url = buildTrackingUrl('productview', {
          userid: config.userID,
          pid: productID
        });
        return get(url);
      }

      function trackCategoryView(categoryID) {
        var url = buildTrackingUrl('categoryview', {
          userid: config.userID,
          cid: categoryID
        });
        return get(url);
      }

      function trackBasketAddition(productIDs, quantities) {
        var pids = productIDs.join(',');
        quantities = quantities.join(',');
        var url = buildTrackingUrl('basket', {
          userid: config.userID,
          pids: pids,
          quantities: quantities
        });
        return get(url);
      }

      function trackOrderCompletion(productIDs, totals, quantities) {
        var pids = productIDs.join(',');
        totals = totals.join(',');
        quantities = quantities.join(',');
        var url = buildTrackingUrl('order', {
          userid: config.userID,
          pids: pids,
          totals: totals,
          quantities: quantities
        });
        return get(url);
      }

      function trackSearchTerm(searchTerm) {
        var url = buildTrackingUrl('search', {
          searchterm: normalizeSearchTerm(searchTerm)
        });
        return get(url);
      }

      function trackProductClick(productID, trackingToken) {
        var url = buildTrackingUrl('click', {
          userid: config.userID,
          pid: productID,
          trackingtoken: trackingToken
        });
        return get(url);
      }

      function trackCategoryClick(categoryID, trackingToken) {
        var url = buildTrackingUrl('categoryclicked', {
          userid: config.userID,
          cid: categoryID,
          trackingtoken: trackingToken
        });
        return get(url);
      }

      function buildRecommendationUrl(svcName, args) {
        return buildRequestUrl(RDE, svcName, args);
      }

      function buildTrackingUrl(eventName, args) {
        return buildRequestUrl(TRACKER, eventName, args);
      }

      function buildRequestUrl(target, eventName, args) {
        return _buildRequestUrl(config, eventName, args, target);
      }

      function get(url) {
        return httpClient(url);
      }
    }
  };
  _exports.default = _default;

  function _buildRequestUrl(config, eventName) {
    var args = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var target = arguments.length > 3 ? arguments[3] : undefined;
    var baseURL = config.targetEndpoints[target]; // sid and tracking parameter are required by all requests

    args.sid = config.sessionID;
    args.tracking = !config.disabled;
    var queryStr = Object.keys(args).filter(function (arg) {
      return args[arg] !== undefined && args[arg] !== null;
    }).map(function (arg) {
      return "".concat(arg, "=").concat(window.encodeURIComponent(args[arg]));
    }).join('&');
    return "".concat(baseURL).concat(eventName, "/?").concat(queryStr);
  }

  function readSession(storageKey) {
    return _localStorage.default.getItem(storageKey);
  }

  function setupSession(storageKey) {
    var sessionID = buildSessionID();

    _localStorage.default.setItem(storageKey, sessionID);

    return sessionID;
  }

  function buildSessionID() {
    // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript#2117523
    // uuidv4 compliant
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  }

  function buildBaseUrl(host, recommendationEngineID, port, target) {
    var fullHost = port ? "".concat(host, ":").concat(port) : host;
    var endpoint = VALID_ENDPOINTS[target];
    if (!endpoint) throw new Error("Invalid Prudsys endpoint ".concat(target, ". Must be one of ").concat(VALID_ENDPOINTS.keys(), "!"));
    return "https://".concat(fullHost, "/rde_server/res/").concat(recommendationEngineID, "/plugins/exec/prudsys/prudsys/").concat(endpoint, "/");
  }

  function normalizeSearchTerm(searchTerm) {
    return searchTerm.replace(/[\s%?&/\\:|]+/g, '-').toLowerCase().slice(0, 49);
  }
});