define("bito-shop-frontend/mixins/mystique-adapter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    mystique: Ember.inject.service(),
    shoeboxID: Ember.computed.reads('mystique.shoeboxID'),
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    // shouldReloadAll() {
    //   return false;
    // },
    // shouldReloadRecord() {
    //   return false;
    // },
    coalesceFindRequests: true,
    spree: Ember.inject.service('mystique'),
    authorizer: 'authorizer:token',
    namespace: Ember.computed.reads('mystique.apiNamespace'),
    host: Ember.computed.reads('mystique.apiHost'),
    // ensure absolute url in fastboot environment
    ajaxOptions: function ajaxOptions() {
      var options = this._super.apply(this, arguments); // const fastbootRequest = this.get('mystique.fastboot.request');
      // if (fastbootRequest) {
      //   const mystique = this.get('mystique');
      //   const httpRegex = /^https?:\/\//;
      //   const protocolRelativeRegex = /^\/\//;
      //
      //   if (protocolRelativeRegex.test(options.url)) {
      //     options.url = mystique.protocol() + options.url;
      //   } else if (!httpRegex.test(options.url)) {
      //     options.url = mystique.baseUrl() + options.url;
      //   }
      // }


      if (this.get('mystique.isFastBoot')) {
        console.log('Request:', options.url); // eslint-disable-line no-console
      }

      return options;
    },
    // populate fastboot shoebox
    ajaxSuccess: function ajaxSuccess(adapter, response
    /*, payload, requestData*/
    ) {
      var returnResponse = this._super.apply(this, arguments);

      if (!response.then) {
        this.get('mystique.shoeboxStore').push(returnResponse);
      }

      return returnResponse;
    }
  });

  _exports.default = _default;
});