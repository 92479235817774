define("bito-shop-frontend/mixins/price-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OPENENDED_RANGE = /^\[(\d+),\)$/;
  var EXCLUSIVE_RANGE = /^\[(\d+),(\d+)\)$/;
  var INCLUSIVE_RANGE = /^\[(\d+),(\d+)\]$/;

  var _default = Ember.Mixin.create({
    getRangeBounds: function getRangeBounds(range) {
      var m;

      if (m = range.match(OPENENDED_RANGE)) {
        return [+m[1], null];
      }

      if (m = range.match(EXCLUSIVE_RANGE)) {
        return [+m[1], m[2] - 1];
      }

      if (m = range.match(INCLUSIVE_RANGE)) {
        return [+m[1], +m[2]];
      }

      throw 'StringToRangeConversionFailed';
    }
  });

  _exports.default = _default;
});