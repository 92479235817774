define("bito-shop-frontend/templates/configurator-taxons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/s0gHBbh",
    "block": "{\"symbols\":[\"configurator\"],\"statements\":[[10,\"div\"],[14,0,\"u-background-seashell\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"unit-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"unit\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"toolbar\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"toolbar__inside\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"toolbar__category\"],[12],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"toolbar__name toolbar__name--full\"],[14,\"title\",\"Konfiguratoren\"],[12],[2,\"Konfiguratoren\"],[13],[2,\" \"],[10,\"span\"],[14,0,\"toolbar__count\"],[12],[2,\"(\"],[1,[32,0,[\"model\",\"configurators\",\"length\"]]],[2,\")\"],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[11,\"button\"],[24,0,\"toolbar__notes\"],[4,[38,0],[[32,0],\"toggleInfoBox\"],null],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"configurators.infobox.howto\"],null]],[2,\"\\n            \"],[8,\"x-icon\",[],[[\"@id\"],[\"question-circle\"]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[8,\"configurator-info-box\",[],[[\"@isActive\",\"@toggle\"],[[32,0,[\"showInfoBox\"]],[30,[36,0],[[32,0],\"toggleInfoBox\"],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"unit unit--main\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sample sample--grid u-background-transparent\"],[12],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"model\",\"configurators\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"single-configurator-item\",[],[[\"@item\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/templates/configurator-taxons.hbs"
  });

  _exports.default = _default;
});