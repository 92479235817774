define("bito-shop-frontend/templates/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MLoBllu3",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"x-signup\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/templates/signup.hbs"
  });

  _exports.default = _default;
});