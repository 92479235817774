define("bito-shop-frontend/components/account-login-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="unit unit--side">
    <div class="nav-side">
      <input type="checkbox" name="nav-side" id="nav-side">
      <label class="nav-side__headline" for="nav-side">{{t "account.label"}}</label>
      <div class="nav-side__content">
        <ul class="nav-side__list">
          <li class="nav-side__item">
            <LinkTo @route="login" class="nav-side__link">
              {{t "base.login"}}
            </LinkTo>
          </li>
          <li class="nav-side__item">
            <LinkTo @route="signup" class="nav-side__link">
              {{t "base.register"}}
            </LinkTo>
          </li>
          <li class="nav-side__item">
            <LinkTo @route="password-reset" class="nav-side__link">
              {{t "account.resetPassword"}}
            </LinkTo>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "Q97PG9Yq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"unit unit--side\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"nav-side\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,3,\"nav-side\"],[14,1,\"nav-side\"],[14,4,\"checkbox\"],[12],[13],[2,\"\\n    \"],[10,\"label\"],[14,0,\"nav-side__headline\"],[14,\"for\",\"nav-side\"],[12],[1,[30,[36,0],[\"account.label\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"nav-side__content\"],[12],[2,\"\\n      \"],[10,\"ul\"],[14,0,\"nav-side__list\"],[12],[2,\"\\n        \"],[10,\"li\"],[14,0,\"nav-side__item\"],[12],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"nav-side__link\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"base.login\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"li\"],[14,0,\"nav-side__item\"],[12],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"nav-side__link\"]],[[\"@route\"],[\"signup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"base.register\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"li\"],[14,0,\"nav-side__item\"],[12],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"nav-side__link\"]],[[\"@route\"],[\"password-reset\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"account.resetPassword\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "bito-shop-frontend/components/account-login-menu.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});