define("bito-shop-frontend/components/pagination-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (gt this.pagesCount 1)}}
    <span
      role="button"
      class="pager__arrow pager__arrow--prev {{unless this.canNavigateBack "is-disabled"}}"
      {{action "prevPage"}}
    />
  
    <ul class="pager__list">
      {{#each this.pagerItems as |pageNumber|}}
        <PagerItem
          @pageNumber={{pageNumber}}
          @currentPage={{@currentPage}}
          @setPage={{action "setPage"}}
        />
      {{/each}}
    </ul>
  
    <span
      role="button"
      class="pager__arrow pager__arrow--next {{unless this.canNavigateForward "is-disabled"}}"
      {{action "nextPage"}}
    />
  {{/if}}
  
  
  */
  {
    "id": "9DyeAiW3",
    "block": "{\"symbols\":[\"pageNumber\",\"@currentPage\"],\"statements\":[[6,[37,5],[[30,[36,4],[[32,0,[\"pagesCount\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"span\"],[24,\"role\",\"button\"],[16,0,[31,[\"pager__arrow pager__arrow--prev \",[30,[36,1],[[32,0,[\"canNavigateBack\"]],\"is-disabled\"],null]]]],[4,[38,0],[[32,0],\"prevPage\"],null],[12],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"pager__list\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"pagerItems\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"pager-item\",[],[[\"@pageNumber\",\"@currentPage\",\"@setPage\"],[[32,1],[32,2],[30,[36,0],[[32,0],\"setPage\"],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[11,\"span\"],[24,\"role\",\"button\"],[16,0,[31,[\"pager__arrow pager__arrow--next \",[30,[36,1],[[32,0,[\"canNavigateForward\"]],\"is-disabled\"],null]]]],[4,[38,0],[[32,0],\"nextPage\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"unless\",\"-track-array\",\"each\",\"gt\",\"if\"]}",
    "moduleName": "bito-shop-frontend/components/pagination-controls.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'div',
    classNames: ['pager'],
    currentPage: '1',
    pagesCount: 1,
    maxPagerItems: 5,
    pagerItems: Ember.computed('pagesCount', 'currentPage', function () {
      var lowerBound = 1;
      var upperBound = this.get('pagesCount');
      var currentPage = Number(this.get('currentPage'));
      var maxPagerItems = this.get('maxPagerItems');
      var pagerItemCount = upperBound < maxPagerItems ? upperBound : maxPagerItems;
      var distance = Math.floor(pagerItemCount / 2);
      var startPage;

      if (currentPage - distance < lowerBound) {
        startPage = lowerBound - 1;
      } else if (currentPage + distance > upperBound) {
        startPage = upperBound - pagerItemCount;
      } else {
        startPage = currentPage - distance - 1;
      }

      return Array.apply([], new Array(pagerItemCount)).map(function () {
        return ++startPage;
      });
    }),
    canNavigateBack: Ember.computed('currentPage', function () {
      return this.get('currentPage') !== '1';
    }),
    canNavigateForward: Ember.computed('currentPage', function () {
      return Number(this.get('currentPage')) !== this.get('pagesCount');
    }),
    actions: {
      prevPage: function prevPage() {
        if (this.get('canNavigateBack')) {
          this.send('setPage', Number(this.get('currentPage')) - 1);
        }
      },
      nextPage: function nextPage() {
        if (this.get('canNavigateForward')) {
          this.send('setPage', Number(this.get('currentPage')) + 1);
        }
      },
      setPage: function setPage(number) {
        this.set('currentPage', number);
        window.scrollTo(0, 0);
      }
    }
  }));

  _exports.default = _default;
});