define("bito-shop-frontend/services/order-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentForm: null,
    // set at init
    buildLineItem: function buildLineItem(variant, quantity) {
      return this.get('store').createRecord('lineItem', {
        quantity: Number(quantity),
        variant: variant
      });
    },
    addLineItem: function addLineItem(lineItem) {
      var lineItems = this.get('currentForm.lineItems');
      var existingLine = lineItems.find(function (l) {
        return l.sku === lineItem.sku;
      });
      if (existingLine) existingLine.set('quantity', existingLine.quantity + lineItem.quantity);else lineItems.unshiftObject(lineItem);
    },
    removeLineItem: function removeLineItem(lineItem) {
      this.get('currentForm.lineItems').removeObject(lineItem);
      lineItem.rollbackAttributes(); // removes item from store
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentForm', this.get('store').createRecord('orderForm'));
    }
  });

  _exports.default = _default;
});