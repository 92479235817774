define("bito-shop-frontend/mixins/recoverable", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _useRecoveryEndpoint: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    setNewPassword: function setNewPassword() {
      var _this = this;

      this.set('_useRecoveryEndpoint', true);
      return this.save().finally(function () {
        _this.set('_useRecoveryEndpoint', false);
      });
    }
  });

  _exports.default = _default;
});