define("bito-shop-frontend/mixins/scroll-to-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    utils: Ember.inject.service(),
    actions: {
      scrollToError: function scrollToError() {
        this.get('utils').scrollTo('.message--error', -200, 800);
      }
    }
  });

  _exports.default = _default;
});