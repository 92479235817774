define("bito-shop-frontend/components/checkout-headline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="headline-grid headline-group grid__col-24">
    <div class="headline-grid__item">
      <h1>{{@title}}</h1>
    </div>
  
    {{yield}}
  </div>
  */
  {
    "id": "d7vUvJJV",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"headline-grid headline-group grid__col-24\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"headline-grid__item\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[32,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "bito-shop-frontend/components/checkout-headline.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});