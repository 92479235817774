define("bito-shop-frontend/components/promotion-line-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="table-cart promotion-line-items">
    <colgroup>
      <col id="row-cart-name">
      <col id="row-cart-delivery-time">
      <col id="row-cart-price">
      <col id="row-cart-actions">
    </colgroup>
    <tbody>
      {{#each @lineItems as |lineItem|}}
        <PromotionLineItem @model={{lineItem}} />
      {{/each}}
    </tbody>
  </table>
  
  */
  {
    "id": "Xy5aCs1h",
    "block": "{\"symbols\":[\"lineItem\",\"@lineItems\"],\"statements\":[[10,\"table\"],[14,0,\"table-cart promotion-line-items\"],[12],[2,\"\\n  \"],[10,\"colgroup\"],[12],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-cart-name\"],[12],[13],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-cart-delivery-time\"],[12],[13],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-cart-price\"],[12],[13],[2,\"\\n    \"],[10,\"col\"],[14,1,\"row-cart-actions\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"promotion-line-item\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "bito-shop-frontend/components/promotion-line-items.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});