define("bito-shop-frontend/components/product-detail-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="detail__badge">
    <img src={{src-set this.url fm=this.format}} loading="lazy" alt={{this.altText}}>
  </span>
  */
  {
    "id": "bHlgKcYa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"detail__badge\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,[36,0],[[32,0,[\"url\"]]],[[\"fm\"],[[32,0,[\"format\"]]]]]],[14,\"loading\",\"lazy\"],[15,\"alt\",[32,0,[\"altText\"]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"src-set\"]}",
    "moduleName": "bito-shop-frontend/components/product-detail-badge.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    url: Ember.computed('icon.url', function computedUrl() {
      return this.icon.url;
    }),
    altText: Ember.computed('icon.altText', function computedAltText() {
      return this.icon.altText;
    }),
    format: Ember.computed('icon.contentType', function computedFormat() {
      return this.icon.contentType === 'application/pdf' ? 'svg' : 'png8';
    })
  }));

  _exports.default = _default;
});